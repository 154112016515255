/**
 * @module
 */

export default class Targets {
  constructor(targets) {
    this.targets = [{source: "*", types: ["*"]}]
    this.isDefaultTargets = true
    if (targets) {
      for (let target of targets) {
        this.addTarget(target)
      }
    }
  }
  
  addTarget(targetToAdd) {
    if (this.isDefaultTargets)
      this.targets = []
    if (targetToAdd.source) {
      this.targets.push(targetToAdd)
      if (!targetToAdd.types) {
        targetToAdd.types = ["*"]
      }
    } else {
      throw new Error("Cannot add target without source")
    }
    this.isDefaultTargets = false
  }
  
  isSearcherTargeted(searcher) {
    if (this.isDefaultTargets) {
      return true
    } else {
      for (let target of this.targets) {
        if (target.source === "*") {
          return true
        }
        if (searcher.hasSource(target.source)) {
          for (let type of target.types) {
            if (type === "*") {
              return true
            } else {
              if (searcher.hasType(type))
                return true
            } 
          }
        }
      }
      return false
    }
  }
  
  include(source, type) {
    if (this.isDefaultTargets) {
      return true
    } else {
      for (let target of this.targets) {
        if (target.source === "*") {
          return true
        }
        if (target.source.toLowerCase() === source.toLowerCase()) {
          for (let targetType of target.types) {
            if (targetType === "*") {
              return true
            } else {
              if (targetType.toLowerCase() === type.toLowerCase())
                return true
            }
          }
        }
      }
      return false
    }
  }
  
}