import coreTypes from './CoreTypes.js'
import dkTypes from './DkTypes.js'
import redataTypes from './searchers/redata/RedataTypes.js'
import geoserverTypes from './searchers/geoserver/GeoserverTypes.js'
import dataApiTypes from './searchers/data-api/DataApiTypes.js'
import GeoFaTypes from './searchers/geofa/GeoFaTypes.js'
import DatafordelerTypes from './searchers/datafordeler/DatafordelerTypes.js'
import TingbogsTypes from './searchers/tingbog/TingbogTypes.js'
import SolPotentialeTypes from "./searchers/solpotentiale/SolPotentialeTypes.js"

let allTypes = coreTypes.spawn()
allTypes.inject(dkTypes)
allTypes.inject(redataTypes)
allTypes.inject(geoserverTypes)
allTypes.inject(dataApiTypes)
allTypes.inject(GeoFaTypes)
allTypes.inject(DatafordelerTypes)
allTypes.inject(TingbogsTypes)
allTypes.inject(SolPotentialeTypes)
export default allTypes
