import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import TagFladeFetcher from "./TagFladeFetcher.js" 

export default class TagFladeSearcher extends Searcher {
  constructor(options={}) {
    super(options)
    this.tagFladeFetcher = new TagFladeFetcher()
    
    this.source = "solpotentiale"

    this.types = { "tagflade": new ResultType({ id: "tagflade", singular: "Tagflade", plural: "Tagflader", queryBehaviour: "none" }) }
    this.registerType(this.source, this.types.tagflade)
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }
  
  async getTagFladerForSfe(sfeResult) {
    let tagfladeReply = await this.tagFladeFetcher.getTagFladerForBfeNummer(sfeResult.id)
    
    return this.orderFladerByBygUuid(tagfladeReply.tagobjekter)
  }

  async getTagfladeItemsForBygning(bygningsResult) {
    let tagfladeReply = await this.tagFladeFetcher.getTagFladerForBygning(bygningsResult.id)
    if (tagfladeReply.tagobjekter)
      return this.sorterTagflader(tagfladeReply.tagobjekter)
    else 
      return []
  }

  orderFladerByBygUuid(tagFlader) {
    let returnValue = {}
    for (let tagFlade of tagFlader) {
      let bbruuid = tagFlade.bbruuid
      if (!returnValue[bbruuid]) {
        returnValue[bbruuid] = []
      }
      returnValue[bbruuid].push(tagFlade)
    }
    for (let bbruuid in returnValue) {
      returnValue[bbruuid] = this.sorterTagflader(returnValue[bbruuid])
    }
    return returnValue
  }
  
  sorterTagflader(tagFlader) {
    let sortFunction = (tagFlade1, tagFlade2) => {
      return tagFlade1.aspect_deg_mean > tagFlade2.aspect_deg_mean
    }
    return tagFlader.sort(sortFunction)
  }


}