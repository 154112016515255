export default class Details {
  constructor(details) {
    this.details = [{source: "*", types: [{type: "*", details: ["*"]}]}]
    this.isDefaultDetails = true
    if (details) {
      for (let detail of details) {
        this.addDetail(detail)
      }
    }
  }

  addDetail(detailToAdd) {
    if (this.isDefaultDetails)
      this.details = []
    if (detailToAdd.source) {
      this.details.push(detailToAdd)
      if (!detailToAdd.types) {
        detailToAdd.types = [{type: "*", details: ["*"]}]
      } else {
        for (let type of detailToAdd.types) {
          if (type.type) {
            if (!type.details)
              type.details = ["*"]
          } else {
            throw new Error("Cannot add type without type")
          }
        }
      }
    } else {
      throw new Error("Cannot add detail without source")
    }
    this.isDefaultDetails = false
  }

  include_org(source, type, detailHandlerId) {
    if (this.isDefaultDetails) {
      return true
    } else {
      for (let detail of this.details) {
        if (detail.source === "*") {
          return true
        }
        if (detail.source.toLowerCase() === source.toLowerCase()) {
          for (let detailType of detail.types) {
            if (detailType.type === "*") {
              return true
            } else {
              if (detailType.type.toLowerCase() === type.toLowerCase()) {
                for (let detailTypeDetail of detailType.details) {
                  if (detailTypeDetail === "*" || detailTypeDetail === detailHandlerId) {
                    return true
                  }
                }
              }
            }
          }
        }
      }
      return false
    }
  }
  
  include(source, type, detailHandlerId) {
    if (this.isDefaultDetails) {
      return true
    } else {
      for (let detail of this.details) {
        if (detail.source === "*") {
          return true
        }
        if (detail.source.toLowerCase() === source.toLowerCase()) {
          for (let detailType of detail.types) {
            if (detailType.type === "*") {
              return true
            } else {
              if (detailType.type.toLowerCase() === type.toLowerCase()) {
                for (let detailTypeDetail of detailType.details) {
                  if (detailTypeDetail === "*" || detailTypeDetail.toLowerCase() === detailHandlerId.toLowerCase()) {
                    return true
                  }
                }
              }
            }
            /*
            if (this.isInTypes(detail.types, detailType.type, detailHandlerId))
              return true
            */
          }
        }
      }
      return false
    }
  }
  
  isInTypes(types, type, detailHandlerId) {
    for (let detailType of types) {
      if (detailType.type === "*") {
        return true
      } else {
        if (detailType.type.toLowerCase() === type.toLowerCase()) {
          for (let detailTypeDetail of detailType.details) {
            if (detailTypeDetail === "*" || detailTypeDetail === detailHandlerId) {
              return true
            }
          }
        }
      }
    }
    return false
  }
  
  getDetailForSource(source) {
    let defaultDetail = null
    for (let detail of this.details) {
      if (detail.source === "*") {
        defaultDetail = detail
      } else if (detail.source.toLowerCase() === source.toLowerCase()) {
        return detail
      }
    }
    return defaultDetail
  }
  
  getDetailForSource2(source) {
    let includeDetail = null
    let excludeDetail = null
    let defaultDetail = null
    for (let detail of this.details) {
      if (detail.source === "*") {
        defaultDetail = detail
      } else if (detail.source.toLowerCase() === source.toLowerCase()) {
        if (defaultDetail) {
          excludeDetail = detail
          includeDetail = defaultDetail
        } else {
          includeDetail = detail
        }
          
      }
    }
    return {includeDetail, excludeDetail}
  }

}