/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import GeoJSON from 'ol/format/GeoJSON.js'
import { getCenter } from 'ol/extent.js'
import { getSize } from 'ol/extent.js'
/**
 * Viser skråfotos fra SDFE
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
  dawa:
    _type: Septima.Search.DawaSearcher
    _options:
      kommunekode: '101'
    detailhandlers:
      - _type: Septima.Search.SkraafotoProvider
        _options:
          buttonText: Skraafotos
          header: SDFE skråfotos
          token: "mytoken"

 * @example <caption> JS options: </caption>
 options = {
    token: mytoken
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.SkraafotoProvider(options))
 *
 * @example <caption>ES6:</caption>
 * import SkraafotoProvider from './searchers/detailhandlers/SkraafotoProvider.js'
 * dawaSearcher.addDetailHandlerDef(new SkraafotoProvider(options))
 * @sspath Septima.Search
 **/
export default class SkraafotoProvider extends DetailsHandlerDef {

  /**
    * @param {Object} options
    * @param {string} options.token Please contact Septima to obtain a valid token for skråfoto api usage
    
    **/
  constructor(options = {}) {
    if (!options.token)
      throw "Skrafoto needs API token in config"
    let defaultOptions = {
      buttonText: "Skråfotos",
      more: false,
      server: "production"
    }
    let finalOptions = Object.assign(defaultOptions, options)
    super(finalOptions)

    this.isApplicableFunction = this.myIsApplicable
    this.handlerFunction = this.myhandler

    this.token = options.token
    this.draw = options.draw
    this.endpoint  = "https://skraafoto.septima.dk"
    this.maxRadius = 600
  }

  myIsApplicable(result) {
    return result.type.hasGeometry
  }
  
  async myhandler(result) {

    let directions = [{ "direction": "n", "text": 'Set fra syd' }, { "direction": "e", "text": 'Set fra vest' }, { "direction": "s", "text": 'Set fra nord' }, { "direction": "w", "text": 'Set fra øst' }]
    let canShow = true
    let center
    let imageRadius
    if (result.geometry) {
      let resgeom = new GeoJSON()

      let olGeom = resgeom.readGeometry(result.geometry, { dataProjection: 'epsg:25832' })
      let extent = olGeom.getExtent()
      center = getCenter(extent)
      let sizes = getSize(extent)
      let imageRadiusFromFeature = Math.trunc(Math.max(...sizes, 30)) //Twice the size of the feature, but minimum 30
      imageRadius = Math.min(this.maxRadius, imageRadiusFromFeature) // but not greater then maxRadius
      if (imageRadiusFromFeature > imageRadius) {
        canShow = false
      }
    } else {
      canShow = false
    }
    
    if (canShow) {
      let drawOptions = {
        radius: imageRadius,
        x: center[0],
        y: center[1],
        drawGeometry: true
      }

      let imagePromises = directions.map(async (d) => {
        return {
          type: "image",
          label: d.text,
          value: await this.getImageUrl(result, d.direction, drawOptions)
        }
      })
      return await Promise.all(imagePromises)
    } else {
      return [
        { type: "labelvalue",
          value: result.title + " kan desværre ikke vises på skråfoto"}
      ]
    }
  }

  async getImageUrl(result, direction, drawOptions) {

    let url
    let body
    let response
    if (drawOptions.drawGeometry) {
      url = `${this.endpoint}/api/image_of_point?token=${this.token}&x=${drawOptions.x}&y=${drawOptions.y}&epsg=25832&direction=${direction}&width=1024&height=600&radius=${drawOptions.radius}`

      let geom = { "z_from": "dtm", "default_style": { "render_color": [255, 50, 0], "render_width": 4, "render_fill_color": [255, 50, 0, 50], "render_invert_polygon": false }, "featurecollection": { "type": "FeatureCollection", "name": "mat", "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::25832" } }, "features": [{ "type": "Feature", "properties": { "fid": 1 } }] } }
        
      geom.featurecollection.features[0].geometry = result.geometry
      body = JSON.stringify(geom)
      response = await fetch(url,
        {
          method: 'POST',
          headers: {
            'Origin':'',
            'Accept': 'image/jpeg',
            'Content-Type': 'application/json'
          },
          body: body
        })
    } else {
      url = `${this.endpoint}/api/image_of_location?token=${this.token}&x=${drawOptions.x}&y=${drawOptions.y}&epsg=25832&direction=${direction}&width=1024&height=600`
      response = await fetch(url,
        {
          headers: {
            'Origin':'',
            'Accept': 'image/jpeg',
            'Content-Type': 'application/json'
          }
        })
    }
    let imageUrl

    if (typeof FileReader === 'undefined') {
      //No FileReader -> not browser (Node.js)
      let arrayBuffer = await response.arrayBuffer()
      let buffer = Buffer.from(arrayBuffer)
      imageUrl = "data:image/jpeg;base64," + buffer.toString('base64')
    }else{
      //Browser
      let blob = await response.blob()
      imageUrl = await this.convertBlobToBase64(blob)
    }

    return imageUrl
  }
  
  convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader
      reader.onerror = reject
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }
  
}
 
