/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import TagFladeSearcher from "./TagFladeSearcher.js"

/**
 * Viser tagflader for ejendom og bygning
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Solpotentiale.TagFlader
 *   _options:
 *     bygningssearcher:
 *       _type: Septima.Search.Datafordeler.BygningsSearcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 * **/
export default class TagFlader extends DetailsHandlerDef {
  constructor(options={}) {

    let defaultOptions = {
      buttonText: "Solpotentiale",
      targets: [{source: "DAF", typeId: "sfe"}, {source: "DAF", typeId: "bbr_bygning"}, {source: "bbr", typeId: "bygning"}]
    }
    super(Object.assign(defaultOptions, options))
    this.tagFladeSearcher = new TagFladeSearcher()

    if(!options.bbrSearcher)
      throw "TagFlader must be created with options.bbrSearcher (Datafordeler.BbrSearcher)"
    this.bbrSearcher = options.bbrSearcher

    this.handlerFunction = this.myHandler
  }

  async myHandler(result) {
    let source = result.source.toLowerCase()
    let typeId = result.typeId.toLowerCase()
    if (source === "daf" && typeId === "sfe")
      return await this.getTagfladeItemsForSfe(result)
    else if ((source === "daf" && typeId === "bbr_bygning") || (source === "bbr" && typeId === "bygning"))
      return await this.getTagfladeItemsForBygning(result)
  }

  async getTagfladeItemsForSfe(sfeResult) {
    let items = []
    let bygsObject = await this.tagFladeSearcher.getTagFladerForSfe(sfeResult)
    let idArray = []
    for (let bbruuid in bygsObject) {
      idArray.push(bbruuid)
    }
    let bygResults = await this.bbrSearcher.gets(idArray, "bygning")
    bygResults = bygResults.filter(function (b) {
      return b.data.status === "3" || b.data.status === "6" || b.data.status === "12" || b.data.status === "13"
    })
    for (let bygResult of bygResults) {
      items.push(this.createTagFladeItemForBygning(bygResult, bygsObject[bygResult.id]))
    }
    return items
  }
  
  async getTagfladeItemsForBygning(bygningsResult) {
    let tagFlader = await this.tagFladeSearcher.getTagfladeItemsForBygning(bygningsResult)
    return [this.createTagFladeItemForBygning(bygningsResult, tagFlader)]
  }
  
  createTagFladeItemForBygning(bygningsResult, tagFlader) {
    let detailItemsList
    if (tagFlader.length > 0) {
      detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: bygningsResult.title,
        infoItemsHeaders: [
          {type: "labelvalue", label: "Potentiale, klasse"},
          {type: "labelvalue", label: "Potentiale, kwh/år"},
          {type: "labelvalue", label: "Tagfladen"}]
      })
      for (let tagflade of tagFlader) {
        detailItemsList.append({
          type: "labelvalue",
          label: "Tagflade, retning/areal",
          value: this.degreesToDirection(tagflade.aspect_deg_mean) + ", " + tagflade.area_sloped + " m2",
          infoItems: [
            {type: "labelvalue", value: this.translate(tagflade.potentiale_type)},
            {type: "labelvalue", value: tagflade.kwh_per_yr_total, valueformat: "int"},
            {type: "labelvalue", value: tagflade.geometri, valueformat: "geometry"}]
        })
      }
    } else {
      detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: bygningsResult.title
      })
      detailItemsList.append({
        type: "labelvalue",
        value: "Der er ikke beregnet et nævneværdigt solpotentiale for tagfladerne" 
      })

    }

    return detailItemsList.asItem()
  }

  degreesToDirection(degrees) {
    // Normalize the degrees to ensure they are within 0-360 range
    degrees = degrees % 360

    if (degrees < 0) {
      degrees += 360
    }

    // Define the directions in Danish for the 8 cardinal points
    const directions = ['Nord', 'Nordøst', 'Øst', 'Sydøst', 'Syd', 'Sydvest', 'Vest', 'Nordvest']

    // Find which segment the degrees fall into
    const index = Math.round(degrees / 45) % 8

    return directions[index]
  }
  
  translate(potentiale_type) {
    switch (potentiale_type) {
      case "low":
        return "Lav"
      case "medium":
        return "Medium"
      case "high":
        return "Høj"
      default:
        return potentiale_type
    }
  }

}