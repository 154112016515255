import {fetch2} from "../../utils.js"

export default class TagFladeFetcher {
  constructor() {
    this.serviceUrl = "https://solenergi-api.k8s-test-127.septima.dk/v1"
  }
  
  async getTagFladerForBfeNummer(bfeNr) {
    return await fetch2(this.serviceUrl + "/bfe/" + bfeNr)
  }
  
  async getTagFladerForBygning(bbrUid) {
    return await fetch2(this.serviceUrl + "/bygning/" + bbrUid)
  }
}