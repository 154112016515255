import Query from "./Query.js"
import Targets from "./filter/Targets.js"


export default class QueryBuilder {
  constructor(baseQuery) {
    if (typeof baseQuery !== 'undefined') {
      this._queryString = baseQuery.queryString
      this._geometry = baseQuery.geometry
      this._limit = baseQuery.limit
      this._type = baseQuery.type
      this._target = {source: baseQuery.target.source, type: baseQuery.target.type}
      this._targets = baseQuery.targets
    }else {
      this._queryString = ""
      this._geometry = null
      this._limit = null
      this._type = null
      this._target = {source: null, type: null}
      this._targets = new Targets()
    }
  }
  
  build() {
    let query = new Query()
    query.queryString = this._queryString
    query.target = {source: this._target.source, type: this._target.type}
    query.targets = this._targets
    query.geometry = this._geometry
    query.limit = this._limit
    query.type = this._type
    return query
  }

  queryString(queryString) {
    this._queryString = queryString
    return this
  }

  geometry(geometry) {
    this._geometry = geometry
    return this
  }

  limit(limit) {
    this._limit = limit
    return this
  }

  type(type) {
    this._type = type
    return this
  }

  target(target) {
    this._target = {source: target.source, type: target.type}
    return this
  }

  targets(targets) {
    this._targets = targets
    return this
  }
  
}
