/**
 * @module
 */
import DetailsHandlerDef from "./DetailsHandlerDef.js"
import jsonpath from "../util/JsonParser.js"
import DetailItemsList from "./DetailItemsList.js"
/**
 * Generic details handler - formats properties of result.data to detailitems
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration</caption>
  dawa:
    _type: Septima.Search.DawaSearcher
    _options:
      resultTypes:
        adresse:
          singular: Adresse
          plural: Adresser
    detailhandlers:
      - _type: Septima.Search.Details
        _options:
          buttonText: Info vha. Septima.Search.Details
          details:
            - type: labelvalue
              label: id
              value: $.properties.id
            - type: labelvalue
              value: ligger i postnr {$.properties.postnummer.nr} {$.properties.postnummer.navn}
            - type: link
              linkTitle: JSON fra DAWA
              link: https://api.dataforsyningen.dk/adgangsadresser/{$.properties.id}
            - type: "result"
              label: "Administrator"
              result:
                searcher:
                  _ref: $.searchers.dataapi.cvr
                type: virksomhed
                id: $.properties.CVR_kode
 * @api
 **/
export default class Details extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} options.details detail items. Fields containing "$." are replaced with result.data property values
   * @param {string} [options.asList=true]
   * @param {string} [options.more=true]
   **/
  constructor(options = {}) {
    if (!options.details)
      throw new Error("FeatureDetails needs options.details")
    let defaultOptions = {
      more: true
    }
    super(Object.assign({}, defaultOptions, options))
    this.items = options.details
    if (options.items)
      this.items = options.items
    this.handlerFunction = this.myHandler
    this.buttonImageHasBeenSpecified = false
    if (options.buttonImage)
      this.buttonImageHasBeenSpecified = true
    this.asList = true
    if (typeof options.asList != 'undefined')
      this.asList = options.asList 
  }

  getbuttonText(result) {
    if (super.getbuttonText() == super.getId())
      //buttonText hasn't been supplied
      return result.title
    else
      return super.getbuttonText()
  }
  
  getbuttonImage(result) {
    if (this.buttonImageHasBeenSpecified)
      //buttonImage hasn't been supplied
      return super.getbuttonImage()
    else
      return result.image
  }

  async myHandler(result) {
    let detailItems = []
    if (result.data) {
      for (let item of this.items) {
        if (item.type === "result" && item.result) {
          let searcher = item.result.searcher
          let type = item.result.type
          let id = item.result.id
          id = this.replaceSelectors(id, result.data).trim()
          if (searcher && id) {
            let itemResult = await searcher.get(id, type)
            if (itemResult) {
              let detailItem = {
                type: "result",
                result: itemResult
              }
              if (item.label)
                detailItem.label = item.label
              detailItems.push(detailItem)
            }
          }
        } else {
          let detailItem = Object.assign({}, item)
          for (let property in detailItem) {
            if (property !== "type") {
              detailItem[property] = this.replaceSelectors(detailItem[property], result.data)
            }
          }
          detailItems.push(detailItem)
        }
      }
      
      if (this.asList && detailItems.length > 0) {
        let detailItemsListOptions = {}
        if (this.more) {
          detailItemsListOptions.header = this.getbuttonText(result)
          detailItemsListOptions.buttonImage = this.getbuttonImage(result)
        }
        let itemTypes = this.getDistinctItemTypes(detailItems)
        if (itemTypes.length === 1)
          detailItemsListOptions.itemType = itemTypes[0]
        let detailItemsList = new DetailItemsList(detailItemsListOptions)
        detailItems.forEach(item => detailItemsList.append(item))
        return [detailItemsList.asItem()]
      }
    }
    return detailItems
  }
  
  replaceSelectors(value, data) {
    if (value.indexOf("$.") === 0) {
      return jsonpath.value(data, value)
    } else {
      let selectorStartPos = value.indexOf("{$.")
      if (selectorStartPos === -1) {
        return value
      } else {
        let valueBeforeSelector = value.slice(0, selectorStartPos)
        let valueAfterSelectorStart = value.slice(selectorStartPos + 1)
        let selectorEndPos = valueAfterSelectorStart.indexOf("}")
        let selector = valueAfterSelectorStart.slice(0, selectorEndPos)
        let valueAfterSelector = valueAfterSelectorStart.slice(selectorEndPos + 1)
        return valueBeforeSelector + jsonpath.value(data, selector) + this.replaceSelectors(valueAfterSelector, data)
      }
    }
  }

  getDistinctItemTypes(items) {
    let types = items.map(item=>item.type)
    let set = new Set(types)
    return [...set ]
  }
}