import BbrKodeliste from "./BbrKodeliste.js"
import {fetch2} from "../../utils.js"
/**
 * @sspath Septima.Search.DataFordeler
 */

export default class Fetcher {
  //https://confluence.sdfi.dk/display/DML/Dokumentation
  constructor(options = {}) {
    this.password = options.password
    this.username = options.username
    this.bbrKodeliste = new BbrKodeliste()
  }

  findBbrKode(codelist, key) {
    return this.bbrKodeliste.find(codelist, key)
  }

  matches(testRegister, testService, testMetode, register, service, metode) {
    return (
      register.toLowerCase() == testRegister.toLowerCase() &&
      service.toLowerCase() == testService.toLowerCase() &&
      metode.toLowerCase() == testMetode.toLowerCase())
  }

  extractParmsFromOptions(options) {
    let parmsFromOptions = ""
    let parmValueArray = []
    for (let parm in options) {
      let value = options[parm]
      parmValueArray.push(parm + "=" + value)
    }
    parmsFromOptions = parmValueArray.join("&")
    return parmsFromOptions
  }
  
  async read(register, service, metode, options, logger) {
    let callUrl
    let parmsFromOptions = this.extractParmsFromOptions(options)
    if (this.matches("matrikel", "matrikel", "samletfastejendom", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/Matriklen2/Matrikel/2.0.0/REST/samletFastEjendom?" + parmsFromOptions
      
    } else if (this.matches("matrikel", "matrikel", "jordstykke", register, service, metode)) {
      //Fake metode for at hente jordstykke
      callUrl = "https://services.datafordeler.dk/Matriklen2/Matrikel/2.0.0/REST/samletFastEjendom?" + parmsFromOptions
      let sfeResponse = await this.fetch(callUrl, logger)
      for (let jordstykke of sfeResponse.features[0].properties.jordstykke) {
        if (jordstykke.properties.matrikelnummer === options.Matrikelnr ) {
          return jordstykke
        }
      }
      return
      
    } else if (this.matches("matrikel", "matrikel", "matrikulaersag", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/Matriklen2/Matrikel/2.0.0/REST/MatrikulaerSag?" + parmsFromOptions

    } else if (this.matches("matrikel", "matrikel", "bestemtfastejendom", register, service, metode)) {
      parmsFromOptions += '&status=' + encodeURIComponent("Gældende")
      callUrl = "https://services.datafordeler.dk/Matriklen2/Matrikel/2.0.0/REST/bestemtfastejendom?" + parmsFromOptions
      
    } else if (this.matches("matrikel", "matrikel", "ejerlejlighed", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/Matriklen2/Matrikel/2.0.0/REST/Ejerlejlighed?" + parmsFromOptions
      
    } else if (this.matches("bbr", "bbr", "ejendomsrelation", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/ejendomsrelation?" + parmsFromOptions      
    } else if (this.matches("bbr", "bbr", "bbrsag", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/bbrsag?" + parmsFromOptions
      
    } else if (this.matches("bbr", "bbr", "bygning", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/bygning?" + parmsFromOptions
      
    } else if (this.matches("dar", "dar", "husnummer", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/DAR/DAR/3.0.0/REST/husnummer?" + parmsFromOptions

    } else if (this.matches("dar", "dar", "husnummertiljordstykke", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/DAR/DAR/3.0.0/REST/husnummerTilJordstykke?" + parmsFromOptions

    } else if (this.matches("dar", "dar", "adresse", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/DAR/DAR/3.0.0/REST/adresse?" + parmsFromOptions
      
    } else if (this.matches("bbr", "bbr", "grund", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/grund?" + parmsFromOptions
      
    } else if (this.matches("bbr", "bbr", "enhed", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/Enhed?" + parmsFromOptions
      
    } else if (this.matches("bbr", "bbr", "opgang", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/Opgang?" + parmsFromOptions
      
    } else if (this.matches("bbr", "bbr", "etage", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/BBR/BBRPublic/1/REST/Etage?" + parmsFromOptions
      
    } else if (this.matches("ejendomsvurdering", "ejendomsvurdering", "HentEjendomsvurderingerForBFE", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/Ejendomsvurdering/Ejendomsvurdering/1/REST/HentEjendomsvurderingerForBFE?" + parmsFromOptions
      
    } else if (this.matches("ejendomsvurdering", "ejendomsvurdering", "HentEjendomsvurderingerForVurderingsejendomID", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/Ejendomsvurdering/Ejendomsvurdering/1/REST/HentEjendomsvurderingerForVurderingsejendomID?" + parmsFromOptions
      
    } else if (this.matches("ebr", "ejendomsbeliggenhed", "ejendomsbeliggenhed", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/EBR/Ejendomsbeliggenhed/1/REST/Ejendomsbeliggenhed?" + parmsFromOptions

    } else if (this.matches("ebr", "ejendomsbeliggenhed", "bfenradresse", register, service, metode)) {
      callUrl = "https://services.datafordeler.dk/EBR/Ejendomsbeliggenhed/1/REST/BFEnrAdresse?" + parmsFromOptions

    }
    if (callUrl) 
      return await this.fetch(callUrl, logger)
  }

  async fetch(url, logger) {
    if (url.indexOf("MATRIKEL") > -1 || url.indexOf("EBR") || url.indexOf("Ejendomsvurdering") > -1) 
      url = url + `&username=${this.username}&password=${this.password}`
    return await fetch2(url, {throwOnHttpError: true}, logger)
  }
}