import ArealerForJordstykkeProvider from "./deprecated/ArealerForJordstykkeProvider.js"
import BbrEnhedInfo from "./deprecated/BbrEnhedInfo.js"
import BbrKodeliste from "./BbrKodeliste.js"
import BoligerForHusnummerProvider from "./BoligerForHusnummerProvider.js"
import VurderingsHandler from "./VurderingsHandler.js"
import SagsoplysningerForJordstykkeProvider from "./SagsoplysningerForJordstykkeProvider.js"
import BbrSagProvider from "./BbrSagProvider.js"
import BbrSearcher from "./BbrSearcher.js"
import OffentligeLinks from "./OffentligeLinks.js"
import KommuneKodeliste from "./KommuneKodeliste.js"
import JordstykkeSekundaerInfoProvider from "./deprecated/JordstykkeSekundaerInfoProvider.js"
import JordstykkerForEjendom from "./JordstykkerForEjendom.js"
import JordstykkeInfoProvider from "./deprecated/JordstykkeInfoProvider.js"
import HusnumreForJordstykke from "./HusnumreForJordstykke.js"
import HusnummerAdresseTilBfeResolver from "./HusnummerAdresseTilBfeResolver.js"
import Fetcher from "./Fetcher.js"
import EnhedsSearcher from "./EnhedsSearcher.js"
import EjendomsSearcher from "./EjendomsSearcher.js"
import EjendomsInfoProvider from "./EjendomsInfoProvider.js"
import EjendomForJordstykkeDawa from "./EjendomForJordstykkeDawa.js"
import BygningsSearcher from "./deprecated/BygningsSearcher.js"
import BygningsInfoProvider from "./deprecated/BygningsInfoProvider.js"
import BygningerForHusnummerJordstykke from "./deprecated/BygningerForHusnummerJordstykke.js"
import BygningerForEjendom from "./deprecated/BygningerForEjendom.js"
import Bygninger from "./Bygninger.js"
import BbrInfo from "./BbrInfo.js"

export default {
  Fetcher,
  EjendomsSearcher,
  EnhedsSearcher,
  BygningsSearcher,
  ArealerForJordstykkeProvider,
  BbrEnhedInfo,
  BbrInfo,
  BbrKodeliste,
  BoligerForHusnummerProvider,
  BygningerForEjendom,
  BygningerForHusnummerJordstykke,
  Bygninger,
  BygningsInfoProvider,
  EjendomForJordstykkeDawa,
  EjendomsInfoProvider,
  HusnummerAdresseTilBfeResolver,
  HusnumreForJordstykke,
  JordstykkeInfoProvider,
  JordstykkerForEjendom,
  JordstykkeSekundaerInfoProvider,
  OffentligeLinks,
  SagsoplysningerForJordstykkeProvider,
  BbrSearcher,
  BbrSagProvider,
  VurderingsHandler,
  KommuneKodeliste
}