/**
 * @module
 */
import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import moment from "moment"
import DawaSearcher from "../DawaSearcher.js"
import HusnummerAdresseTilBfeResolver from "./HusnummerAdresseTilBfeResolver.js"
import {reproject} from "../../util/reproject.js"
import {GeoJSON, WFS} from "ol/format.js"
import {equalTo, intersects as intersectsFilter, or as orFilter} from 'ol/format/filter.js'
import GML3 from "ol/format/GML3.js"
import GeoSearch from "../geosearch/GeoSearch.js"
import {MultiPolygon} from "ol/geom.js"
/**
 * Søger bbr-sager datafordeleren.bbr
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.BbrSagSearcher
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Datafordeler.Fetcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 **/
export default class BbrSearcher extends Searcher {
  /**
   * @param {Object} options
   * @param {Object} options.fetcher Septima.Search.Datafordeler.Fetcher instance
   * @param {Object} options.geosearcher Septima.Search.GeoSearcher instance   **/
  constructor(options) {
    options.usesGeoFunctions = true
    super(options)
    this.fetcher = options.fetcher
    this.geosearcher = new GeoSearch({})
    this.dawaSearcher = new DawaSearcher({})
    this.source = "BBR"

    this.types = {
      "sag": new ResultType({ id: "sag", singular: "Sag", plural: "Sager", queryBehaviour: "none", geometrySupport: "none" }),
      "enhed": new ResultType({ id: "enhed", singular: "Enhed", plural: "Enheder", queryBehaviour: "none" }),
      "grund": new ResultType({ id: "grund", singular: "Grund", plural: "Grunde", queryBehaviour: "none" }),
      "bygning": new ResultType({ id: "bygning", singular: "Bygning", plural: "Bygninger", queryBehaviour: "none" }) 
    }    
    
    /*
    "opgang": new ResultType({ id: "opgang", singular: "Opgang", plural: "Opgange", queryBehaviour: "none" }),
     "etage": new ResultType({ id: "etage", singular: "Etage", plural: "Etager", queryBehaviour: "none" })
    */
     
    this.registerType(this.source, this.types.sag)
    this.registerType(this.source, this.types.enhed)
    this.registerType(this.source, this.types.grund)
    this.registerType(this.source, this.types.bygning)
    //this.registerType(this.source, this.types.opgang) //Findes ikke på DAF
    //this.registerType(this.source, this.types.etage) //Findes ikke på DAF
    
    this.authParamsDatafordeler = options.authParamsDatafordeler || {
      username: this.fetcher.username,
      password: this.fetcher.password
    }
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }

  async sqTypes() {
    return [this.types.bygning]
  }

  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject(query.geometry, null, "EPSG:25832")
      let olGeometry = new GeoJSON().readGeometry(queryGeometry)

      if (olGeometry) {
        let bbruuids = await this.getBbrUuidsFromGeometry(olGeometry)

        let bygninger = await this.gets(bbruuids, 'bygning')

        for (let bygning of bygninger)
          queryResult.addResult(bygning)
      }
    }
    return queryResult
  }

  async getBygningerForBfeResult(bfeResult) {
    let geoms = {}
    let queryResult = this.createQueryResult()
    let bfeNummer = bfeResult.id
    if (bfeResult.typeId === "sfe") {
      let grundeResponse = await this.fetcher.read("bbr", "bbr", "grund", {BFEnummer: bfeNummer}, this.getLogger())
      grundeResponse = grundeResponse.filter((g) => g.status === "7")
      for (let grund of grundeResponse) {
        let grundId = grund.id_lokalId
        let bygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", {grund: grundId, Pagesize: 1000}, this.getLogger())
        await this.addBygningerResponseToQueryResult(bygningerResponse, queryResult, geoms)
      }
    } else if (bfeResult.typeId === "bfg" || bfeResult.typeId === "ejl" ) {
      let bygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", {BFENummer: bfeNummer, Pagesize: 1000}, this.getLogger())
      await this.addBygningerResponseToQueryResult(bygningerResponse, queryResult, geoms)
    }
    let results = queryResult.getResults()
    results.sort(this.compareBuildings)
    return queryResult.getResults()
  }
  
  async addBygningerResponseToQueryResult(bygningerResponse,queryResult, geoms) {
    bygningerResponse = bygningerResponse.filter(function (b) {
      return b.status === "3" || b.status === "6" || b.status === "12" || b.status === "13"
    })
    for (let bygning of bygningerResponse) {
      geoms[bygning.id_lokalId.toLowerCase()] = bygning.byg404Koordinat ? this.translateWktToGeoJsonObject(bygning.byg404Koordinat, "25832") : null
    }
    await this.fixGeoms(geoms)
    for (let bygning of bygningerResponse) {
      await this.bygningToQueryResult(bygning, queryResult, geoms)
    }
    return queryResult
  }

  compareBuildings(a, b) {
    try {
      let title_a = a.title.split('-')[1]
      let title_b = b.title.split('-')[1]

      let sortvalue = title_a.localeCompare(title_b)

      if (sortvalue == 0) {
        let title_a = a.title.split('-')[0]
        let title_b = b.title.split('-')[0]
        sortvalue = title_a.localeCompare(title_b)
      }

      return sortvalue
    } catch (error) {
      return 0
    }
  }

  async getBbrUuidsFromGeometry(geometry) {
    try {
      if (typeof geometry === 'undefined')
        return null
      let bbrgeometry = geometry
      let featureNS = 'http://data.gov.dk/schemas/geodanmark60/2/gml3'
      let featurePrefix = 'gdk60'
      const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')

      let url = `https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark60_NOHIST_GML3/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature`
      let featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:25832',
        featureNS,
        featurePrefix,
        featureTypes: ['Bygning'],
        outputFormat: 'xml',
        filter: intersectsFilter('geometri', bbrgeometry)
      })
      let xml = await this.fetch(url, {
        method: 'post',
        expects: 'xml',
        // eslint-disable-next-line no-undef
        body: new XMLSerializer().serializeToString(featureRequest)
      })
      xml = this.santizeXml(xml)

      const format = new GML3()
      const features = format.readFeatures(xml)
      const text = new GeoJSON().writeFeatures(features)
      let geojson = JSON.parse(text)
      const bbrUiids = []
      geojson.features.forEach((f) => {
        if (typeof f.properties.BBRUUID !== 'undefined')
          bbrUiids.push(f.properties.BBRUUID)
      })
      const uniquebbrUiids = Array.from(new Set(bbrUiids)) //Set data object removes duplicates when passed array 
      return uniquebbrUiids
    } catch (e) {
      return null
    }
  }

  santizeXml(xml) {
    xml = xml.replace('http://www.opengis.net/gml/3.2', 'http://www.opengis.net/gml')
    xml = xml.replace('xmlns:wfs="http://www.opengis.net/wfs/2.0"', 'xmlns:wfs="http://www.opengis.net/gml"')
    xml = xml.split('wfs:member').join('wfs:featureMember')
    xml = xml.replace(/(\d),0.0/g, '$1') // Remove z-values from coordinates
    xml = xml.replace(/(\d),(\d)/g, '$1 $2') // Remove , from coordinates
    xml = xml.replace(/<gml:coordinates/g, '<gml:posList srsDimension="2"')
    xml = xml.replace(/<\/gml:coordinates/g, '</gml:posList')
    return xml
  }
  
  async getBygningerForJordstykke(jordstykkeid) {
    let bygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", {jordstykke: jordstykkeid, Pagesize: 1000}, this.getLogger())
    bygningerResponse = bygningerResponse.filter(function (b) {
      return b.status === "3" || b.status === "6" || b.status === "12" || b.status === "13"
    })
    let geoms = {}
    for (let bygning of bygningerResponse) {
      geoms[bygning.id_lokalId.toLowerCase()] = bygning.byg404Koordinat ? this.translateWktToGeoJsonObject(bygning.byg404Koordinat, "25832") : null
    }
    await this.fixGeoms(geoms)
    
    let queryResult = this.createQueryResult()
    let resultPromises = []

    for (let bygningResponse of bygningerResponse)
      resultPromises.push(this.bygningToQueryResult(bygningResponse, queryResult, geoms))
    await Promise.all(resultPromises)
    const sorter = function (b1, b2) {
      try {
        if (b1.data.byg007Bygningsnummer === b2.data.byg007Bygningsnummer)
          return (0)
        else
          return (b1.data.byg007Bygningsnummer - b2.data.byg007Bygningsnummer)
      } catch (error) {
        return 0
      }
    }
    return queryResult.getResults().sort(sorter)
  }

  async gets (idArray, type) {
    //idArray.join("|")
    let queryResult = this.createQueryResult()
    switch (type) {
      case "grund": {
        let bbrGrundResponses = await this.fetcher.read("bbr", "bbr", "grund", { id: idArray.join("|") }, this.getLogger())
        for (let bbrGrundResponse of bbrGrundResponses) {
          await this.grundToQueryResult(bbrGrundResponse, queryResult)
        }
        return queryResult.getResults()
      }
      case "sag": {
        let bbrSagResponses = await this.fetcher.read("bbr", "bbr", "bbrsag", { id: idArray.join("|") }, this.getLogger())
        for (let bbrSagResponse of bbrSagResponses) {
          await this.sagToQueryResult(bbrSagResponse, queryResult)
        }
        return queryResult.getResults()
      }
      case "enhed": {
        let bbrEnhedResponses = await this.fetcher.read("bbr", "bbr", "enhed", { id: idArray.join("|") }, this.getLogger())
        bbrEnhedResponses.filter((e) => e.status === "6")
        for (let bbrEnhedResponse of bbrEnhedResponses) {
          await this.enhedToQueryResult(bbrEnhedResponse, queryResult)
        }
        return queryResult.getResults()
      }
      case "bygning": {
        let resultPromises = []
        while (idArray.length > 0) {
          let idsToRead = idArray.splice(0, 150)
          let bbrBygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", { id: idsToRead.join("|") }, this.getLogger())
          for (let bbrBygningResponse of bbrBygningerResponse)
            resultPromises.push(this.bygningToQueryResult(bbrBygningResponse, queryResult))
        }
        await Promise.all(resultPromises)

        const sorter = function (b1, b2) {
          try {
            if (b1.data.byg007Bygningsnummer === b2.data.byg007Bygningsnummer)
              return (0)
            else
              return (b1.data.byg007Bygningsnummer - b2.data.byg007Bygningsnummer)
          } catch (error) {
            return 0
          }
        }
        return queryResult.getResults().sort(sorter)
      }
    }
    return null

  }

  async get(id, type) {
    let resultater = await this.gets([id], type)
    return resultater[0]
  }

  async grundToQueryResult(bbrData, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let husnummerId = bbrData.husnummer
    let dawaResult = await this.dawaSearcher.get(husnummerId, "adresse")
    let adgangsadressebetegnelse = dawaResult.title
   
    //let jordstykkerListResponse = await this.fetcher.read("matrikel", "matrikel", "jordstykke", { id: bbrData.jordstykkeList[0] }, this.getLogger())
    
    let jordstykkerForGrund = []
    let mergedgeoms
    
    if (bbrData.jordstykkeList) {
      for (let j of bbrData.jordstykkeList) {
        let jordstykke = await this.geosearcher.get(j, 'matrikelnumre')
        jordstykkerForGrund.push(jordstykke.geometry)
      }
      mergedgeoms = this.mergePolygonGeometries(jordstykkerForGrund)
    }
    let title = `${adgangsadressebetegnelse} (${this.fetcher.findBbrKode("Livscyklus", bbrData.status)})`
    let description =  `Antal jordstykker: ${bbrData.jordstykkeList ? bbrData.jordstykkeList.length: 0}`
    let result = queryResult.addResult(this.source, this.types.grund.id, title, description, mergedgeoms, bbrData)
    result.id = bbrData.id_lokalId
    return queryResult
  }
  //let bbrBygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", { id: idsToRead.join("|") }, this.getLogger())
  sagToQueryResult(bbrData, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let title = `${bbrData.sag001Byggesagsnummer} (${this.fetcher.findBbrKode("Livscyklus", bbrData.status)})`
    let description =  `Byggesagsdato: ${moment(bbrData.sag002Byggesagsdato).format('DD-MM-YYYY')} - ${this.fetcher.findBbrKode("Byggesagskode", bbrData.sag012Byggesagskode)}`
    let result = queryResult.addResult(this.source, this.types.sag.id, title, description, null, bbrData)
    result.id = bbrData.id_lokalId
    return queryResult
  }

  async enhedToQueryResult(bbrData, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let adressAccessId = bbrData.adresseIdentificerer
    let adressResult = await this.dawaSearcher.get(adressAccessId, "enhedsadresse")
    let anvendelseTekst = this.fetcher.findBbrKode("EnhAnvendelse", bbrData.enh020EnhedensAnvendelse)
    let status = this.fetcher.findBbrKode("Livscyklus", bbrData.status)
    let title = `${adressResult.title}`
    let description =  `${anvendelseTekst} (${status})`
    let result = queryResult.addResult(this.source, this.types.enhed.id, title, description, adressResult.geometry, bbrData)
    result.id = bbrData.id_lokalId
    return queryResult
  }
  async bygningToQueryResult(bbrBygningResponse, queryResult, geoms) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let adgangsadressebetegnelse = ""
    try {
      let husnummerId = bbrBygningResponse.husnummer
      let dawaResult = await this.dawaSearcher.get(husnummerId, "adresse")
      adgangsadressebetegnelse = " - " + dawaResult.title
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

    let title = `BYG: ${bbrBygningResponse.byg007Bygningsnummer ? bbrBygningResponse.byg007Bygningsnummer: '(Intet nummer)'}  ${adgangsadressebetegnelse}`
    let type = this.fetcher.findBbrKode("BygAnvendelse", bbrBygningResponse.byg021BygningensAnvendelse)
    let status = await this.fetcher.findBbrKode("Livscyklus", bbrBygningResponse.status)
    if (type)
      title = title + " (" + type + ")"

    let etagecount = bbrBygningResponse.byg054AntalEtager

    let etage = ""
    if (etagecount) {
      if (etagecount > 1)
        etage = " - " + etagecount + " etager"
      else
        etage = " - " + etagecount + " etage"
    }
    let description = ""
    if (bbrBygningResponse.byg038SamletBygningsareal) {
      description = "Samlet areal: " + bbrBygningResponse.byg038SamletBygningsareal + "m2"
    } else {
      if (bbrBygningResponse.byg041BebyggetAreal) {
        description = "Bebygget areal: " + bbrBygningResponse.byg041BebyggetAreal + "m2"
      }
    }
    description +=  etage + " (" + status + ")"

    let geometry = null
    if (geoms) {
      geometry = geoms[bbrBygningResponse.id_lokalId]
    } else {
      if (bbrBygningResponse.byg404Koordinat)
        geometry = await this.getBuildingGeometry(bbrBygningResponse.id_lokalId)
      if (geometry === null && bbrBygningResponse.byg404Koordinat)
        geometry = this.translateWktToGeoJsonObject(bbrBygningResponse.byg404Koordinat, "25832")
    }

    let result = queryResult.addResult(this.source, this.types.bygning.id, title, description, geometry)
    result.id = bbrBygningResponse.id_lokalId
    result.data = bbrBygningResponse
    return queryResult
  }

  sorter(b1, b2) {
    try {
      return (b1.status - b2.status)
    } catch (error) {
      return 0
    }
  }

  async getSagerForGrund(result) {
    let queryResult = this.createQueryResult()
    if (result.typeId === "adresse") {
      let husnummer = result.id

      let husnummerTilBfeResolver = new HusnummerAdresseTilBfeResolver({fetcher: this.fetcher, medtagadresser: this.medtagAdresser})
      let {sfe, bfg, ejl} = await husnummerTilBfeResolver.resolveHusnummer(husnummer)
      if (sfe.length > 0 || bfg.length > 0 || ejl.length > 0) {
        let grundResponse = await this.fetcher.read("bbr", "bbr", "grund", {BFENummer: sfe}, this.getLogger())
        grundResponse = grundResponse.filter((e) => e.status === "7")
        let grund = grundResponse[0].id_lokalId
        let bbrsagerResponse = await this.fetcher.read("bbr", "bbr", "bbrsag", { Grund: grund }, this.getLogger())
        bbrsagerResponse.sort(this.sorter)
        for (let bbrSagResponse of bbrsagerResponse) {
          await this.sagToQueryResult(bbrSagResponse, queryResult)
        }
        return queryResult.getResults()
      }else
        return []
    }
    if (result.typeId === "matrikelnumre") {
      let jordstykkeid = result.data.lokalId
      let grundResponse = await this.fetcher.read("bbr", "bbr", "grund", {Jordstykke: jordstykkeid}, this.getLogger())
      grundResponse = grundResponse.filter((e) => e.status === "7")
      let grund = grundResponse[0].id_lokalId
      let bbrsagerResponse = await this.fetcher.read("bbr", "bbr", "bbrsag", { Grund: grund }, this.getLogger())
      bbrsagerResponse.sort(this.sorter)
      for (let bbrSagResponse of bbrsagerResponse) {
        await this.sagToQueryResult(bbrSagResponse, queryResult)
      }
      return queryResult.getResults()
    }else
      return []
  }

  
  async getSagerForBygning(result) {
    let queryResult = this.createQueryResult()
    let bygningid = result.id
    const bbrsagerResponse = await this.fetcher.read("bbr", "bbr", "bbrsag", { Bygning: bygningid }, this.getLogger())
    bbrsagerResponse.sort(this.sorter)
    for (let bbrSagResponse of bbrsagerResponse) {
      await this.sagToQueryResult(bbrSagResponse, queryResult)
    }
    return queryResult.getResults()
  }

  mergePolygonGeometries(polygonGeometries) {
    //Any need to merge?
    if (polygonGeometries.length === 1)
      return polygonGeometries[0]
    let multiPolygonGeometry = {
      "type": "MultiPolygon",
      "coordinates": []
    }
    for (let geometry of polygonGeometries) {
      if (geometry !== null) {
        if (geometry.type === "Polygon")
          multiPolygonGeometry.coordinates.push(geometry.coordinates)
        else if (geometry.type === "MultiPolygon")
          multiPolygonGeometry.coordinates = [...multiPolygonGeometry.coordinates, ...geometry.coordinates]
      }
    }
    if (multiPolygonGeometry.coordinates.length > 0) {
      if (polygonGeometries[0].crs)
        multiPolygonGeometry.crs = polygonGeometries[0].crs
      return multiPolygonGeometry
    } else {
      return null
    }
  }

  async fixGeoms(geoms) {
    let filters = []
    let filter
    for (let id_lokalId in geoms) {
      filters.push( equalTo('BBRUUID', id_lokalId, false) )
    }
    if (filters.length > 0) {
      if (filters.length === 1) {
        filter = filters[0]
      } else {
        filter = orFilter(...filters)
      }
      let geojson = await this.getGeoJsonFromFilter(filter)
      for (let feature of geojson.features) {
        let geom = this.getGeomFromFeature(feature)
        geoms[feature.properties.BBRUUID.toLowerCase()] = geom
      }
    }
  }

  getGeomFromFeature(feature) {
    // use first feature to create multipolygon
    let firstCoordinates = feature.geometry.coordinates

    let mp = new MultiPolygon([firstCoordinates], 'XY')

    let resGeom = new GeoJSON({ dataProjection: 'epsg:25832', featureProjection: 'epsg:25832' }).writeGeometryObject(mp)

    resGeom.crs = {
      "type": "name",
      "properties": {
        "name": "epsg:25832"
      }
    }
    return resGeom
  }
  
  async getBuildingGeometry(id_lokalId) {
    try {
      if (typeof id_lokalId === 'undefined')
        return null

      let eqfilter = equalTo('BBRUUID', id_lokalId, false) //skal nok bruges når der er index bå BBRUUID på DFD

      let geojson = await this.getGeoJsonFromFilter(eqfilter)

      if (geojson.features.length === 0)
        return null

      let resGeom = this.getGeomFromFeature(geojson.features[0])
      return resGeom

    } catch (e) {
      return null
    }
  }

  async getGeoJsonFromFilter(filter) {
    let featureNS = 'http://data.gov.dk/schemas/geodanmark60/2/gml3'
    let featurePrefix = 'gdk60'
    const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')

    let url = `https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark60_NOHIST_GML3/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature`
    let featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:25832',
      featureNS,
      featurePrefix,
      featureTypes: ['Bygning'],
      outputFormat: 'xml',
      filter: filter
    })
    let xml = await this.fetch(url, {
      method: 'post',
      expects: 'xml',
      // eslint-disable-next-line no-undef
      body: new XMLSerializer().serializeToString(featureRequest)
    })
    xml = this.santizeXml(xml)

    const format = new GML3()
    const features = format.readFeatures(xml)
    const text = new GeoJSON().writeFeatures(features)
    let geojson = JSON.parse(text)
    return geojson
  }





}