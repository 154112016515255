import Targets from "./filter/Targets.js"

export default class Query {
  constructor() {
    this.queryString = ""
    this.targets = new Targets()
    this.target = {source: null, type: null}
    this.geometry = null
    this.limit = null
    this.type = null
  }
  
  clone() {
    let query = new Query()
    query.queryString = this.queryString
    query.target = {source: this.target.source, type: this.target.type}
    query.geometry = this.geometry
    query.limit = this.limit
    query.type = this.type
    // list: return results until limit
    // cut: return results until limit - show new-query for the rest
    // no-cut return results if they fit in limit
    // collapse only return new-queries
    return query
  }

  get hasTarget() {
    return (this.target.source !== null && this.target.type !== null)
  }

  /*
  get hasTargets() {
    return (!this.targets.isDefaultTarget)
  }
   */
  
  get isBlank() {
    return (this.queryString === null || this.queryString === "")
  }

  isSearcherTargeted(searcher) {
    return this.targets.isSearcherTargeted(searcher)
  }
  
  isSearcherToBeQueried(thisSearcher) {
    return (this.getSearcherTypesToBeQueried(thisSearcher) > 0)
  }

  getSearcherTypesToBeQueried(thisSearcher) {
    let types = []
    if (this.isSearcherTargeted(thisSearcher)) {
      if (!(this.isBlank && thisSearcher.blankBehavior === 'none')) {
        let searcherSources = thisSearcher.getSources()
        for (let searcherSource of searcherSources) {
          for (let searcherType of searcherSource.types) {
            if (this.includes(searcherSource.source, searcherType.id) && searcherType.queryBehaviour !== 'none') {
              types.push({source: searcherSource.source, typeId: searcherType.id})
            }
          }
        }
      }
    }
    return types
  }
  
  includes(source, type) {
    return this.targets.include(source, type)
  }

}


