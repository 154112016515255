/**
 * @module
 */

import {fetch2} from "../../utils.js"
import Cache from "../../util/Cache.js"
import * as reproject from "../../util/reproject.js"
/** 
 *  
 * Makes an array of objects searchable
 *
 * @example <caption>YAML Declaration:</caption>
persons:
  searchablepersons:
    _type: Septima.Search.SearchableData
    _options:
      singular: Person
      plural: Persons
      searchProperties:
        - name
        - hobby
      displaynameProperty: name
      descriptionProperty: hobby
      idProperty: number
      data:
        _ref: "$.persons.persons"
  persons:
    - number: 1
      name: Adam
      age: 15
      hobby: Chess
    - number: 3
      name: Christine
      age: 17
      hobby: Math
    - number: 2
      name: Bob
      age: 16
      hobby: Soccer
    - number: 4
      name: Jenny
      age: 18
      hobby: Geography

 * @example <caption> JS options:</caption>
 persons = [
 {number: 1, name: "Adam", age: 15, hobby: "Chess"},
 {number: 3, name: "Christine", age: 17, hobby: "Math"},
 {number: 2, name: "Bob", age: 16, hobby: "Soccer"},
 {number: 4, name: "Jenny", age: 18, hobby: "Geography"},
 ]

 searchAbleDataOptions = {
    singular: "Person",
    plural: "Personer",
    searchProperties: ["name", "hobby"],
    displaynameProperty: "name",
    descriptionProperty: "age",
    idProperty: "number",
    data: persons
  }
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * var searchAbleData = new Septima.Search.SearchableData(searchAbleDataOptions)
 * controller.addSearcher(new Septima.Search.DataSearcher(searchAbleData))
 *
 * @example <caption>ES6:</caption>
 * import DataSearcher from './searchers/DataSearcher.js'
 * import SearchableData from './searchers/local/SearchableData.js'
 * let searchAbleData = new SearchableData(searchAbleDataOptions)
 * controller.addSearcher(new DataSearcher(searchAbleData))
 *
 * @api */
  

/*
Fra https://www.mixmax.com/engineering/default-options-in-es6:
  f({ singular,
    useAND = true,
    searchProperties = [],
    displaynameProperty,
    descriptionProperty,
    geometryProperty,
    idProperty,
    data = [],
    srid} = {}) {

  }
 */
export default class SearchableData {


  /**
   *
   * @param {Object} options SearchableData expects these properties:
   * @param options.data {object} Array of data, or a function that returns an array of data, or a string URL returning a json array. This way the client could change the data on the fly and not keep them static. This could be used when adding an external filter. Please see options.cacheTTL
   * @param options.searchProperties {string[]} Array of property names in the data array to search in. If not added, all properties will be used
   * @param options.displaynameProperty {string} The name of the property in the data array that should be used as displayname
   * @param options.descriptionProperty {string} The name of the property in the data array that should be used as description
   * @param [options.useAND=true] {boolean} Use AND and not OR when multiple terms is added by the user
   * @param options.singular {string} Singular phrase, eg.: "feature"
   * @param options.plural {string} Plural phrase, eg.: "features"
   * @param [options.cacheTTL=60] {int}Applicable to urls and functions (see options.data) How long should data be cached in seconds(0->no cache)
   * @param [options.srid] {string}Read geometry as in EPSG:srid projection (Else use projection from data)
   */
  constructor(options={}) {
    this.singular = options.singular
    this.plural = options.plural
    this.useAND = (options.useAND !== false)
    this.searchProperties = options.searchProperties || []
    this.displaynameProperty = options.displaynameProperty || null
    this.descriptionProperty = options.descriptionProperty || null
    this.geometryProperty = options.geometryProperty || null
    this.idProperty = options.idProperty || null
    this.data = options.data || []
    this.srid = options.srid || null
    
    if (options.getDisplayname) 
      this.getDisplayname = options.getDisplayname
    
    if (options.getDescription) 
      this.getDescription = options.getDescription
    
    if (options.getGeometry) 
      this.getGeometry = options.getGeometry
    
    this.sortMode = "search" // search / filter
    if (options.sortMode)
      this.sortMode = options.sortMode
    
    let cacheOptions = {}
    if (options.cacheTTL)
      cacheOptions.ttl = options.cacheTTL

    this.cache = new Cache(cacheOptions)
    
  }
	
  async _getData() {
    if (this.data instanceof Function) {
      //this.data may be async
      return await Promise.resolve(this.data())
    } else if (typeof this.data == "string") {
      let data = this.cache.get("data")
      if (data) {
        return data
      } else {
        let data = await fetch2(this.data)
        this.cache.set("data", data)
        return data
      }
    } else {
      return this.data
    }
  }
	
  async getAll() {
    const objectsToSearch = await this._getData()
    let hits = []
    for (let currentObject of objectsToSearch) {
      let hit = {
        object: currentObject,
        title: this.getDisplayname(currentObject),
        description: this.getDescription(currentObject),
        geometry: this.getGeometry(currentObject)
      }
      hits.push(hit)
    }
    return hits
  }
  
  async query(queryString) {
    const objectsToSearch = await this._getData()
    const resultset = []

    const queryTerms = queryString.split(" ")
    if (queryTerms.length>0) {
      for (let currentObject of objectsToSearch) {
        let hit = {
          score: 0,
          object: currentObject,
          title: this.getDisplayname(currentObject)
        }
        if (hit.title !== null) {
          if (queryString === '') {
            hit.score = 1
          }else{
            let andcount = 0
            let andscore = 0
            for (let term of queryTerms) {
              const score = this.match(hit, term)
              if (score > 0) {
                andcount++
                andscore += score
              }
            }
            if (this.useAND) {
              if (andcount === queryTerms.length) 
                hit.score = andscore
              
            } else {
              hit.score = andscore
            }
          }
					
          if (hit.score > 0) {
            if (this.idProperty) 
              hit.id = this.getId(currentObject)
            
            hit.description = this.getDescription(currentObject)
            hit.geometry = this.getGeometry(currentObject)
            resultset.push(hit)
          }
        }
      }
      if (resultset.length > 0 && this.sortMode === "search") 
        resultset.sort((hit1, hit2)=>this.compareHits(hit1, hit2))
      
    }
    return resultset
  }

  isGettable() {
    return (this.idProperty !== null)
  }
  
  async get(id) {
    const objectsToSearch = await this._getData()
    for (let currentObject of objectsToSearch) 
      if (this.getId(currentObject) == id) 
        return {
          score: 0,
          object: currentObject,
          title: this.getDisplayname(currentObject),
          description: this.getDescription(currentObject),
          geometry: this.getGeometry(currentObject),
          id: id
        }
    return null
  }

  compareHits(hit1, hit2) {
    if (hit2.score === hit1.score) 
      if (isNaN(hit1.title) || isNaN(hit2.title)) 
        return (hit1.title.toString().localeCompare(hit2.title.toString()))
      else 
        return hit1.title-hit2.title
      
    else
      return hit2.score-hit1.score
    
  }
	
  getId(object) {
    let id = null
    if (this.idProperty) 
      id = object[this.idProperty]
    
    return id
  }
  getDisplayname(object) {
    let displayName = null
    if (this.displaynameProperty) 
      displayName = object[this.displaynameProperty]
    
    return displayName
  }
	
  getDescription(object) {
    let description = ''
    if (this.descriptionProperty) 
      description = '' + object[this.descriptionProperty]
    return description
  }

  getGeometry(object) {
    let geometry = null
    if (this.geometryProperty && object[this.geometryProperty])
      geometry =  reproject.reproject(object[this.geometryProperty], this.srid, "EPSG:25832")
    return geometry
  }

  match(potentialHit, str) {
    let score = 0
    if (str === "") {
      score = 1
    }else{
      //Factor two for scores in title
      score = 2 * this.getScore(potentialHit.title, str)
      let valueToSearch
      if (this.searchProperties.length) 
        for (let searchProperty of this.searchProperties) {
          valueToSearch = potentialHit.object[searchProperty]
          if (valueToSearch && valueToSearch !== null && valueToSearch !== '') 
            score += this.getScore(valueToSearch, str)
          
        }
      else 
        for (let name in potentialHit.object) {
          valueToSearch = potentialHit.object[name]
          if (valueToSearch !== null && valueToSearch !== '') 
            score += this.getScore(valueToSearch, str)
          
        }
      
    }
    return score
  }

  getScore(stringIn, searchstr) {
    const val = stringIn.toString()
    if (val.toLowerCase().indexOf(searchstr.toLowerCase()) === 0) 
      return 2
    else if (val.toLowerCase().indexOf(' ' + searchstr.toLowerCase()) > 0) 
      return 1
    else
      return 0
    
  }

}
