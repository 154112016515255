/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import DawaSearcher from "../DawaSearcher.js"
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser info for cvr.produktionsenhed eller cvr.virksomhed resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.DataApi.CvrSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.DataApi.CvrInfoProvider
 *     _options:
 *       fields_virksomhed: ["cvrnummer", "navn", "virksomhedstype", "formaal", "virkningsdato", "adresse", "adresse_result", "email", "tlf", "hovedbranche", "hovedafdelingkode", "hovedgruppekode", "gruppekode", "undergruppekode", "branchekode", "antalansatte","virksomhed_cvrlink", "enhedsliste"]
         fields_produktionsenhed: ["pnummer","navn", "adresse", "adresse_result", "email", "tlf", "branchekode", "cvrlink","virksomhed_navn", "virksomhed_result", "virksomhed_cvrlink", "virksomhed_enhedsliste","hovedafdelingkode", "hovedgruppekode", "gruppekode", "undergruppekode", "antalansatte"]
         virksomhed_enhedsliste_komnumre: 101 147
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.CvrInfoProvider({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrInfoProvider.js'
 * dawaSearcher.addDetailHandlerDef(new CvrInfoProvider({more: false}))
 * @sspath Septima.Search.DataApi
 **/
export default class CvrInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {String[]} [options.fields_produktionsenhed=["pnummer","navn", "adresse", "adresse_result", "email", "tlf", "branchekode", "cvrlink","virksomhed_navn", "virksomhed_result", "virksomhed_cvrlink", "virksomhed_enhedsliste","hovedafdelingkode", "hovedgruppekode", "gruppekode", "undergruppekode", "antalansatte"]]
   * @param {String[]} [options.fields_virksomhed=["cvrnummer", "navn", "virksomhedstype", "formaal", "virkningsdato", "adresse", "adresse_result", "email", "tlf", "hovedbranche", "hovedafdelingkode", "hovedgruppekode", "gruppekode", "undergruppekode", "branchekode", "antalansatte","virksomhed_cvrlink", "enhedsliste"]]

  * @param {String[]} [options.virksomhed_enhedsliste_komnumre] Kommunenumre i hvilke produktionsenheder for cvr-nummer skal fremsøges. Mellemrums-separereret (157 101)
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "CVR-info",
      buttonImage: icons.searchers.cvr,
      virksomhed_enhedsliste_komnumre: "101",
      targets: [{ typeId: ["virksomhed", "produktionsenhed"] }]
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler

    this.fields_produktionsenhed = ["pnummer","navn", "adresse", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_result", "virksomhed_cvrlink", "virksomhed_enhedsliste", "hovedafdelingkode", "hovedgruppekode", "gruppekode", "undergruppekode", "branchekode","antalansatte"]
    this.fields_virksomhed = ["cvrnummer", "navn", "virksomhedstype", "formaal", "virkningsdato", "adresse", "adresse_result", "email", "tlf", "hovedafdelingkode", "hovedgruppekode", "gruppekode", "undergruppekode", "branchekode", "antalansatte", "virksomhed_cvrlink", "enhedsliste"]
    if (options.fields_produktionsenhed && options.fields_produktionsenhed != ["*"])
      this.fields_produktionsenhed = options.fields_produktionsenhed
    if (options.fields_virksomhed && options.fields_virksomhed != ["*"])
      this.fields_virksomhed = options.fields_virksomhed

    if (typeof options.virksomhed_enhedsliste_komnumre !== 'undefined')
      this.virksomhed_enhedsliste_komnumre = "" + options.virksomhed_enhedsliste_komnumre

    this.dawaSearcher = new DawaSearcher({})
    if (options.dawaSearcher)
      this.dawaSearcher = options.dawaSearcher
    else
      this.dawaSearcher = new DawaSearcher({})

  }

  async myHandler(result) {
    let items = []
    let virksomhed
    let produktionsenhed

    if (result.typeId === "produktionsenhed") {
      let cvrNummer = result.data.cvrnummer
      //produktionsenhed = result
      produktionsenhed = await result.searcher.get(result.id, "produktionsenhed")
      virksomhed = await result.searcher.get(cvrNummer, "virksomhed")
      for (let field of this.fields_produktionsenhed) {
        let item = await this.createPItem(produktionsenhed, field, virksomhed)
        if (item)
          items.push(item)
      }
    } else if (result.typeId === "virksomhed") {
      //virksomhed = result
      virksomhed = await result.searcher.get(result.id, "virksomhed")
      for (let field of this.fields_virksomhed) {
        let item = await this.createVItem(virksomhed, field)
        if (item)
          items.push(item)
      }
    }
    return items
  }



  // eslint-disable-next-line no-unused-vars
  async createVItem(virksomhed, field) {
    if (field === 'cvrnummer') {
      return {
        type: 'labelvalue',
        label: 'CVRnummer',
        'value': virksomhed.id
      }
    } else if (field === 'virksomhed_cvrlink' && virksomhed) {
      let cvrNummer = virksomhed.id
      return {
        type: 'link',
        icon: icons.exlink,
        label: `${virksomhed.title} på virk.dk`,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrNummer}`,
        linkTitle: `Virksomheden (${cvrNummer}) på virk.dk`
      }

    } else if (field === 'virksomhedstype') {
      return {
        type: 'labelvalue',
        label: `Virksomhedstype`,
        value: `${virksomhed.data.virksomhedstype}`
      }
    } else if (field === 'virkningsdato') {
      return {
        type: 'labelvalue',
        label: `Virkningsdato`,
        value: `${virksomhed.data.virkningsdato}`
      }
    } else if (field === 'navn') {
      return {
        type: 'labelvalue',
        label: `Navn`,
        value: `${virksomhed.data.navn}`
      }
    } else if (field === 'hovedbranche' && virksomhed.data.branche) {
      return {
        type: 'labelvalue',
        label: `Hovedbranche`,
        value: `${virksomhed.data.branche}`
      }
    } else if (field === 'hovedafdelingkode' && virksomhed.data.hovedafdelingkode) {
      return {
        type: 'labelvalue',
        label: `Hovedafdelingkode`,
        value: `${virksomhed.data.branchekode.undergruppekode.gruppekode.hovedgruppekode.hovedafdelingkode.hovedafdeling} (${virksomhed.data.hovedafdelingkode})`
      }
    } else if (field === 'hovedgruppekode' && virksomhed.data.hovedgruppekode) {
      return {
        type: 'labelvalue',
        label: `Hovedgruppekode`,
        value: `${virksomhed.data.branchekode.undergruppekode.gruppekode.hovedgruppekode.hovedgruppe} (${virksomhed.data.hovedgruppekode})`
      }
    } else if (field === 'gruppekode' && virksomhed.data.gruppekode) {
      return {
        type: 'labelvalue',
        label: `Gruppekode`,
        value: `${virksomhed.data.branchekode.undergruppekode.gruppekode.gruppe} (${virksomhed.data.gruppekode})`
      }
    } else if (field === 'undergruppekode' && virksomhed.data.undergruppekode) {
      return {
        type: 'labelvalue',
        label: `Undergruppekode`,
        value: `${virksomhed.data.branchekode.undergruppekode.undergruppe} (${virksomhed.data.undergruppekode})`
      }
    } else if (field === 'branchekode' && virksomhed.data.branchekode) {
      return {
        type: 'labelvalue',
        label: `Branche`,
        value: `${virksomhed.data.branche} (${virksomhed.data.branchekode.branchekode})`
      }
    } else if (field === 'formaal' && virksomhed.data.formaal) {
      return {
        type: 'labelvalue',
        label: `Formål`,
        value: `${virksomhed.data.formaal}`
      }
    } else if (field === 'adresse') {
      if (typeof virksomhed.data.adgangsadressebetegnelse != 'undefined')
        return {
          type: 'labelvalue',
          label: `Adresse`,
          value: virksomhed.data.adgangsadressebetegnelse
        }
    } else if (field === 'adresse_result') {
      if (virksomhed.data.husnummerid) {
        let adresseResult = await this.dawaSearcher.get(virksomhed.data.husnummerid, 'adresse')
        return {
          type: 'result',
          label: 'Adresse',
          result: adresseResult
        }
      }
    } else if (field === 'email') {
      if (virksomhed.data.email)
        return {
          type: 'labelvalue',
          label: `Email`,
          value: virksomhed.data.email || 'Ikke angivet'
        }
    } else if (field === 'tlf') {
      if (virksomhed.data.tlf)
        return {
          type: 'labelvalue',
          label: `Telefon`,
          value: virksomhed.data.tlf || 'Ikke angivet'
        }
    } else if (field === 'enhedsliste') {
      return await this.getProduktionsEnhederForCvrNummer(virksomhed.searcher, virksomhed.id, this.virksomhed_enhedsliste_komnumre)
    } else if (field === 'antalansatte') {
      if (typeof virksomhed.data.antalansatte != 'undefined')
        return {
          type: 'labelvalue',
          label: `Antal ansatte`,
          value: virksomhed.data.antalansatte
        }
    }

  }
  async createPItem(produktionsenhed, field, virksomhed) {

    if (field === 'navn') {
      return {
        type: 'labelvalue',
        label: `Navn`,
        value: `${produktionsenhed.data.navn}`
      }
    } else if (field === 'pnummer') {
      return {
        type: 'labelvalue',
        label: `P-nummer`,
        value: `${produktionsenhed.id}`
      }
    } else if (field === 'hovedafdelingkode' && produktionsenhed.data.hovedafdelingkode) {
      return {
        type: 'labelvalue',
        label: `Hovedafdelingkode`,
        value: `${produktionsenhed.data.branchekode.undergruppekode.gruppekode.hovedgruppekode.hovedafdelingkode.hovedafdeling} (${produktionsenhed.data.hovedafdelingkode})`
      }
    } else if (field === 'hovedgruppekode' && produktionsenhed.data.hovedgruppekode) {
      return {
        type: 'labelvalue',
        label: `Hovedgruppekode`,
        value: `${produktionsenhed.data.branchekode.undergruppekode.gruppekode.hovedgruppekode.hovedgruppe} (${produktionsenhed.data.hovedgruppekode})`
      }
    } else if (field === 'gruppekode' && produktionsenhed.data.gruppekode) {
      return {
        type: 'labelvalue',
        label: `Gruppekode`,
        value: `${produktionsenhed.data.branchekode.undergruppekode.gruppekode.gruppe} (${produktionsenhed.data.gruppekode})`
      }
    } else if (field === 'undergruppekode' && produktionsenhed.data.undergruppekode) {
      return {
        type: 'labelvalue',
        label: `Undergruppekode`,
        value: `${produktionsenhed.data.branchekode.undergruppekode.undergruppe} (${produktionsenhed.data.undergruppekode})`
      }
    } else if (field === 'branchekode' && produktionsenhed.data.branchekode) {
      return {
        type: 'labelvalue',
        label: `Branchekode`,
        value: `${produktionsenhed.data.branche} (${produktionsenhed.data.branchekode.branchekode})`
      }
    } else if (field === 'virksomhed_result') {
      return {
        type: 'result',
        label: 'Tilhørende virksomhed',
        result: virksomhed
      }
    } else if (field === 'email') {
      if (produktionsenhed.data.septima_email == null) {
        return {
          type: 'labelvalue',
          label: 'Email',
          value: 'Ikke angivet'
        }
      } else {
        let email = produktionsenhed.data.septima_email
        return {
          type: 'link',
          label: `Email`,
          link: 'mailto:' + email,
          linkTitle: email
        }
      }
    } else if (field === 'tlf') {
      if (produktionsenhed.data.septima_tlf == null) {
        return {
          type: 'labelvalue',
          label: 'Telefon',
          value: 'Ikke angivet'
        }
      } else {
        let tlf = produktionsenhed.data.septima_tlf
        return {
          type: 'link',
          label: `Telefon`,
          value: tlf,
          link: 'tel:' + tlf,
          linkTitle: tlf
        }

      }
    } else if (field === 'adresse_result') {
      if (produktionsenhed.data.husnummerid) {
        let adresseResult = await this.dawaSearcher.get(produktionsenhed.data.husnummerid, 'adresse')
        return {
          type: 'result',
          label: 'Adresse',
          result: adresseResult
        }
      }
    } else if (field === 'adresse') {
      if (typeof produktionsenhed.data.adgangsadressebetegnelse != 'undefined')
        return {
          type: 'labelvalue',
          label: `Adresse`,
          value: produktionsenhed.data.adgangsadressebetegnelse
        }
    } else if (field === 'virksomhed_navn' && virksomhed) {
      let virksomhedsNavn = virksomhed.title
      return {
        type: 'labelvalue',
        label: `Virksomhed`,
        value: virksomhedsNavn
      }
    } else if (field === 'virksomhed_cvrlink' && virksomhed) {
      let cvrNummer = virksomhed.id
      return {
        type: 'link',
        icon: icons.exlink,
        label: `${virksomhed.title} på virk.dk`,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrNummer}`,
        linkTitle: `Virksomheden (${cvrNummer}) på virk.dk`
      }

    } else if (field === 'cvrlink') {
      return {
        type: 'link',
        icon: icons.bbrhouse,
        label: `${produktionsenhed.title} på virk.dk`,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=produktionsenhed&id=${produktionsenhed.id}`,
        linkTitle: `Produktionsenheden (${produktionsenhed.id}) på virk.dk`
      }
    } else if (field === 'virksomhed_enhedsliste' && virksomhed) {
      return await this.getProduktionsEnhederForCvrNummer(virksomhed.searcher, virksomhed.id, this.virksomhed_enhedsliste_komnumre)
    }
  }

  async getProduktionsEnhederForCvrNummer(cvrSearcher, cvrNummer, enhedsliste_komnumre) {
    let queryResult = await cvrSearcher.getProduktionsEnhederForCvrNummer(cvrNummer, enhedsliste_komnumre, true)
    let pResults = queryResult.getResults()
    if (pResults.length > 0) {
      let detailItemsList = new DetailItemsList({ itemType: "result", header: "Virksomhedens produktionsenheder", image: pResults[0].image })
      for (let pResult of pResults)
        detailItemsList.append({
          type: "result",
          result: pResult,
          name: "cvr_info_produktionsenheder"
        })
      return detailItemsList.asItem()
    }

  }
}
