import Targets from "./Targets.js"
import Details from "./Details.js"

export default class Filter {
  constructor(filter) {
    this.targets = new Targets(filter?.targets)
    this.details = new Details(filter?.details)
  }
  
  toJson() {
    return JSON.stringify({targets: this.targets.targets, details: this.details.details})
  }
  
}