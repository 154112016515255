import ClassRegistry from "../../ClassRegistry.js"
import TagFlader from "./TagFlader.js"


const solPotentialeTypes = new ClassRegistry("Septima.Search.SolPotentiale.")

const typesToAdd = {
  TagFlader
}

for (let [key, value] of Object.entries(typesToAdd))
  solPotentialeTypes.addClass(value, key)

export default solPotentialeTypes