/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from '../../resources/icons.js'
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser info for Vedtagne lokalplaner, byggefelter, delområder, kommuneplanrammer og regionplaner
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:<a href='../examples/plansystemsearcher/'>Example</a></caption>
    plansystemsearcher:
      _type: Septima.Search.PlanSystemSearcher
      _options:
        minimumShowCount: 3
        targets:
          - vedtagetlokalplan
          - kommuneplanramme
          - kommuneplantillaeg
          - regionplan
          - byggefelt
          - delområde
        searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
      detailhandlers:
        - _type: "Septima.Search.PlanInfoProvider"
          _options:
            fields:
              lp: [plannr', 'plannavn', 'anvendelsegenerel', 'doklink', 'anvspec', 'datovedt', 'datoforsl']
              kpl: ['planid','plannavn','objektkode','komplan_id','anvendelsegenerel','fzone','zone', 'plannr','distrikt', 'anvspec', 'sforhold', 'notomranv','notanvgen','notbebygom', 'notmiljo','notinfras', 'notzone','notlokalp','notat','minuds','bebygpct','bebygpctaf','eareal','m3_m2','m3_m2h','maxbygnhjd','maxetager','bebygpctar','iomfangreg','iudstykreg','udstykforbud','datoforsl', 'datovedt','datoaflyst','datoikraft','datostart','datoslut','saflysdato','datooprt','datoopdt','planstatus','komnr','doklink','minmiljo','maxmiljo','kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold']
              kpt: ['planid','plannavn','objektkode','komtil_id','anvendelsegenerel','fzone','zone', 'plannr','distrikt', 'anvspec', 'sforhold', 'notomranv','notanvgen','notbebygom', 'notmiljo','notinfras', 'notzone','notlokalp','notat','minuds','bebygpct','bebygpctaf','eareal','m3_m2','m3_m2h','maxbygnhjd','maxetager','bebygpctar','iomfangreg','iudstykreg','udstykforbud','datoforsl', 'datovedt','datoaflyst','datoikraft','datostart','datoslut','saflysdato','datooprt','datoopdt','planstatus','komnr','doklink','minmiljo','maxmiljo','kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold']
 * @example <caption> JS options: </caption>
 options = {
    fields: {
      lp: [plannr', 'plannavn', 'anvendelsegenerel', 'doklink', 'anvspec', 'datovedt', 'datoforsl']
      kpl: ['planid','plannavn','objektkode','komplan_id','anvendelsegenerel','fzone','zone', 'plannr','distrikt', 'anvspec', 'sforhold', 'notomranv','notanvgen','notbebygom', 'notmiljo','notinfras', 'notzone','notlokalp','notat','minuds','bebygpct','bebygpctaf','eareal','m3_m2','m3_m2h','maxbygnhjd','maxetager','bebygpctar','iomfangreg','iudstykreg','udstykforbud','datoforsl', 'datovedt','datoaflyst','datoikraft','datostart','datoslut','saflysdato','datooprt','datoopdt','planstatus','komnr','doklink','minmiljo','maxmiljo','kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold']
      kpt: ['planid','plannavn','objektkode','komtil_id','anvendelsegenerel','fzone','zone', 'plannr','distrikt', 'anvspec', 'sforhold', 'notomranv','notanvgen','notbebygom', 'notmiljo','notinfras', 'notzone','notlokalp','notat','minuds','bebygpct','bebygpctaf','eareal','m3_m2','m3_m2h','maxbygnhjd','maxetager','bebygpctar','iomfangreg','iudstykreg','udstykforbud','datoforsl', 'datovedt','datoaflyst','datoikraft','datostart','datoslut','saflysdato','datooprt','datoopdt','planstatus','komnr','doklink','minmiljo','maxmiljo','kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold']
    }
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * planSystemSearcher.addDetailHandlerDef(new Septima.Search.PlanInfoProvider(options))
 *
 * @example <caption>ES6:</caption>
 * import PlanInfoProvider from './searchers/detailhandlers/PlanInfoProvider.js'
 * planSystemSearcher.addDetailHandlerDef(new PlanInfoProvider(options))
 * @sspath Septima.Search
 **/

export default class PlanInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields] Felter, der skal medtages i den viste info
   * @param {string[]} [options.fields.lp = ["doklink","anvendelsegenerel","ianvreg", "plannr", "zone","izonereg","distrikt","datoforsl", "datovedt", "datoikraft","sforhold","iomfangreg","bebygpct","bebygpctaf","bebygpctar","eareal","earealh","m3_m2","m3_m2h","maxetager","maxmiljo","minmiljo","megawatt","iudstykreg","udsbefor","maxbygnhjd","minuds","abestem1","abestem2","bevarbest","kompleks","byggefelter_result", "delomraader_result"]] For lokalplaner
   * @param {string[]} [options.fields.lpf = ["doklink","anvendelsegenerel","ianvreg", "plannr", "zone","izonereg","distrikt","datoforsl", "datovedt", "datoikraft","sforhold","iomfangreg","bebygpct","bebygpctaf","bebygpctar","eareal","earealh","m3_m2","m3_m2h","maxetager","maxmiljo","minmiljo","megawatt","iudstykreg","udsbefor","maxbygnhjd","minuds","abestem1","abestem2","bevarbest","kompleks","byggefelter_result", "delomraader_result"]] For lokalplaner
   * @param {string[]} [options.fields.kpl = ["planid","plannavn","objektkode","komplan_id","anvendelsegenerel","fzone","zone", "plannr","distrikt", "anvspec", "sforhold", "notomranv","notanvgen","notbebygom", "notmiljo","notinfras", "notzone","notlokalp","notat","minuds","bebygpct","bebygpctaf","eareal","m3_m2","m3_m2h","maxbygnhjd","maxetager","bebygpctar","iomfangreg","iudstykreg","udstykforbud","datoforsl", "datovedt","datoaflyst","datoikraft","datostart","datoslut","saflysdato","datooprt","datoopdt","planstatus","komnr","doklink","minmiljo","maxmiljo","kommuneplan_anvendelse","kommuneplan_bebyggelse","kommuneplan_trafik_parkering","kommuneplan_zonebestemmelser","kommuneplan_miljoe","kommuneplan_andreforhold"]] For kommuneplanrammer
   * @param {string[]} [options.fields.kpt = ["planid","plannavn","objektkode","komtil_id","anvendelsegenerel","fzone","zone", "plannr","distrikt", "anvspec", "sforhold", "notomranv","notanvgen","notbebygom", "notmiljo","notinfras", "notzone","notlokalp","notat","minuds","bebygpct","bebygpctaf","eareal","m3_m2","m3_m2h","maxbygnhjd","maxetager","bebygpctar","iomfangreg","iudstykreg","udstykforbud","datoforsl", "datovedt","datoaflyst","datoikraft","datostart","datoslut","saflysdato","datooprt","datoopdt","planstatus","komnr","doklink","minmiljo","maxmiljo","kommuneplan_anvendelse","kommuneplan_bebyggelse","kommuneplan_trafik_parkering","kommuneplan_zonebestemmelser","kommuneplan_miljoe","kommuneplan_andreforhold"]] For kommuneplantillæg
   * @param {string[]} [options.fields.bf = ["delnr", "doklink", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]] For byggefelter
   * @param {string[]} [options.fields.do = ["delnr", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]] For delområder
   * @param {string[]} [options.fields.ko = ["objekt_id", "cvrname", "bruger_id", "navn1201","vaerd1201a","vaerd1201b,"""staar1201","sluaar1201", "datoforsl", "datovedt", "datoikraft"]] For kloakoplande
   * @param {string[]} [options.fields.kou = ["objekt_id", "cvrname", "bruger_id", "navn1201","vaerdi1206"]] For Mulighed for udtræden kloakoplande
   * @param {string[]} [options.fields.zs = ["planid", "kommunenavn", "zonestatus"]] For zonestatus
   * @param {string[]} [options.fields.knz = ["gml_id", "navn", "region","cpr_noegle"]] For kystnærhedszone
   * @param {string[]} [options.fields.lzt = ["id", "planid", "komnr","kommunenavn","lztnavn","lztnr","datoikraft","datoudloeb","doklink","aktuel","aenanvbyg","aenanvarea","tekanlaeg","andet","planstatus","matrbetegn"]] For landzonetilladelse
   * @param {string[]} [options.fields.tpk = ["id", "hovedstruk", "navn_gen","navn_spec","kode","kortbilag","dato_ret"]]  For transportkorridor

  
 
  * @param {string[]} [options.fields.rp = ["amt", "doklink"]] For regionplaner
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: ""
    }

    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.handler

    //Default fields
    this.fields = {}
    this.fields.lp = ["doklink", "anvendelsegenerel", "plannr", "datoforsl", "datovedt", "datoikraft", "sforhold", "iomfangreg", "bebygpct", "bebygpctaf", "bebygpctar", "eareal", "earealh", "m3_m2", "m3_m2h", "maxetager", "maxmiljo", "minmiljo", "iudstykreg", "byggefelter_result", "delomraader_result"]
    this.fields.lpf = ["doklink", "anvendelsegenerel", "plannr", "datoforsl", "datovedt", "datoikraft", "sforhold", "iomfangreg", "bebygpct", "bebygpctaf", "bebygpctar", "eareal", "earealh", "m3_m2", "m3_m2h", "maxetager", "maxmiljo", "minmiljo", "iudstykreg", "byggefelter_result", "delomraader_result"]
    this.fields.kpl = ["anvendelsegenerel", "plannr", "plannavn", "anvspec", "sforhold", "notomranv", "datoforsl", "datovedt", "datoaflyst", "datoikraft", "datostart", "datoslut"]
    this.fields.kpt = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt"]
    this.fields.bf = ["delnr", "doklink", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]
    this.fields.do = ["delnr", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]
    this.fields.rp = ["amt", "doklink"]
    this.fields.ko = ["objekt_id", "cvrname", "bruger_id", "navn1201", "vaerd1201a", "vaerd1201b", "staar1201", "sluaar1201", "datoforsl", "datovedt", "datoikraft"]
    this.fields.kou = ["objekt_id", "navn1201", "vaerdi1206"]
    this.fields.zs = ["planid", "kommunenavn", "zonestatus"]
    this.fields.knz = ["gml_id", "navn", "region","cpr_noegle"]
    this.fields.lzt = ["id", "planid", "komnr","kommunenavn","lztnavn","lztnr","datoikraft","datoudloeb","doklink","aktuel","aenanvbyg","aenanvarea","tekanlaeg","andet","planstatus","matrbetegn"]
    this.fields.tpk = ["id", "hovedstruk", "navn_gen","navn_spec","kode","kortbilag","dato_ret"]




    //Read fields from options
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined') {
      if (typeof options.fields.lp !== 'undefined')
        this.fields.lp = options.fields.lp
      if (typeof options.fields.lpf !== 'undefined')
        this.fields.lf = options.fields.lpf
      if (typeof options.fields.kpl !== 'undefined')
        this.fields.kpl = options.fields.kpl
      if (typeof options.fields.kpt !== 'undefined')
        this.fields.kpt = options.fields.kpt
      if (typeof options.fields.bf !== 'undefined')
        this.fields.bf = options.fields.bf
      if (typeof options.fields.do !== 'undefined')
        this.fields.do = options.fields.do
      if (typeof options.fields.rp !== 'undefined')
        this.fields.rp = options.fields.rp
      if (typeof options.fields.ko !== 'undefined')
        this.fields.ko = options.fields.ko
      if (typeof options.fields.kou !== 'undefined')
        this.fields.ko = options.fields.kou
      if (typeof options.fields.zs !== 'undefined')
        this.fields.zs = options.fields.zs
      if (typeof options.fields.lzt !== 'undefined')
        this.fields.lzt = options.fields.lzt
      if (typeof options.fields.knz !== 'undefined')
        this.fields.knz = options.fields.knz
      if (typeof options.fields.tpk !== 'undefined')
        this.fields.tpk = options.fields.tpk


    }
  }

  getbuttonText(result) {
    if (this.buttonText != '') {
      return this.buttonText
    } else {
      let tabtext = 'Om planen'
      if (result.typeId === "vedtagetlokalplan")
        tabtext = 'Om lokalplanen'
      else if (result.typeId === "lokalplanforslag")
        tabtext = 'Om lokalplanforslaget'
      else if (result.typeId === "kommuneplanramme")
        tabtext = 'Om kommuneplanrammen'
      else if (result.typeId === "kommuneplantillaeg")
        tabtext = 'Om kommuneplantillægget'
      else if (result.typeId === "regionplan")
        tabtext = 'Om regionplanen'
      else if (result.typeId === "byggefelt")
        tabtext = 'Om byggefeltet'
      else if (result.typeId === "delområde")
        tabtext = 'Om delområdet'
      else if (result.typeId === "vedtagetkloakopland")
        tabtext = 'Om kloakoplandet'
      else if (result.typeId === "mulighedforudtraedenkloakopland")
        tabtext = 'Om kloakoplandet'
      else if (result.typeId === "zonestatus")
        tabtext = 'Om zonen'
      else if (result.typeId === "landzonetilladelse")
        tabtext = 'Om landzonetilladelsen'
      else if (result.typeId === "kystnaerhedszone")
        tabtext = 'Om kystnærhedszonen'
      else if (result.typeId === "transportkorridor")
        tabtext = 'Om transportkorridoren'
      return tabtext
    }
  }

  async handler(result) {
    let anvspecvals = []

    if (result.typeId === "lokalplanforslag" || result.typeId === "vedtagetlokalplan" || result.typeId === "kommuneplanramme" || result.typeId === "kommuneplantillaeg" || result.typeId === "byggefelt" || result.typeId === "delområde")
      Object.entries(result.data.properties).map(obj => {
        const key = obj[0]
        const value = obj[1]
        if (key.startsWith('anvspec') && value)
          anvspecvals.push(result.searcher.getAnvendelsesTekstFraKode(value))
      })

    let items = []
    if (result.typeId === "vedtagetlokalplan")
      for (let field of this.fields.lp) {
        let item = await this.createLpItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "lokalplanforslag")
      for (let field of this.fields.lp) {
        let item = await this.createLpItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)
      } else if (result.typeId === "kommuneplanramme")
      for (let field of this.fields.kpl) {
        let item = this.createKplItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "kommuneplantillaeg")
      for (let field of this.fields.kpt) {
        let item = this.createKplItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "byggefelt")
      for (let field of this.fields.bf) {
        let item = await this.createBfItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "delområde")
      for (let field of this.fields.do) {
        let item = await this.createDoItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "regionplan")
      for (let field of this.fields.rp) {
        let item = await this.createRpItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      } else if (result.typeId === "zonestatus")
      for (let field of this.fields.zs) {
        let item = await this.createZsItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    else if (result.typeId === "landzonetilladelse")
      for (let field of this.fields.lzt) {
        let item = await this.createLztItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    else if (result.typeId === "kystnaerhedszone")
      for (let field of this.fields.knz) {
        let item = await this.createKnzItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    else if (result.typeId === "transportkorridor")
      for (let field of this.fields.tpk) {
        let item = await this.createTpkItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    else if (result.typeId === "vedtagetkloakopland")
      for (let field of this.fields.ko) {
        let item = await this.createKoItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    else if (result.typeId === "mulighedforudtraedenkloakopland")
      for (let field of this.fields.kou) {
        let item = await this.createKouItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }

    return items

  }
  createKouItem(result, field) {
    let props = result.data.properties
    if (field === 'objekt_id')
      return {
        type: 'labelvalue',
        label: 'PlanID',
        value: props.objekt_id
      }
    if (field === 'navn1201')
      return {
        type: 'labelvalue',
        label: 'Navn',
        value: props.navn1206
      }
    else if (field === 'cvrname')
      return {
        type: 'labelvalue',
        label: 'CVR navn',
        value: props.cvrname
      }
    else if (field === 'bruger_id')
      return {
        type: 'labelvalue',
        label: 'Bruger',
        value: props.bruger_id
      }
    else if (field === 'vaerdi1206')
      return {
        type: 'labelvalue',
        label: 'Mulighed for udtræden',
        value: props.vaerdi1206
      }
    else if (field === 'staar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlægt ændring af status',
        value: props.staar1201 || 'Ikke angivet'
      }
    else if (field === 'sluaar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlagt ikrafttrædelse',
        value: props.sluaar1201 || 'Ikke angivet'
      }
    else if (field === 'datoforsl') {
      return this.writeDateItem('Dato forslag', props.datoforsl)
    } else if (field === 'datovedt') {
      return this.writeDateItem('Dato vedtaget', props.datovedt)
    } else if (field === 'datoikraft') {
      return this.writeDateItem('Dato i kraft', props.datoikraft)
    }
    return
  }
  createKoItem(result, field) {
    let props = result.data.properties
    if (field === 'objekt_id')
      return {
        type: 'labelvalue',
        label: 'PlanID',
        value: props.objekt_id
      }
    if (field === 'navn1201')
      return {
        type: 'labelvalue',
        label: 'Områdenavn',
        value: props.navn1201
      }
    else if (field === 'cvrname')
      return {
        type: 'labelvalue',
        label: 'CVR navn',
        value: props.cvrname
      }
    else if (field === 'bruger_id')
      return {
        type: 'labelvalue',
        label: 'Bruger',
        value: props.bruger_id
      }
    else if (field === 'vaerd1201a')
      return {
        type: 'labelvalue',
        label: 'Kloaktype',
        value: props.vaerd1201a
      }
    else if (field === 'vaerd1201b')
      return {
        type: 'labelvalue',
        label: 'Planlagt kloaktype',
        value: props.vaerd1201b
      }
    else if (field === 'staar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlægt ændring af status',
        value: props.staar1201 || 'Ikke angivet'
      }
    else if (field === 'sluaar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlagt ikrafttrædelse',
        value: props.sluaar1201 || 'Ikke angivet'
      }
    else if (field === 'datoforsl') {
      return this.writeDateItem('Dato forslag', props.datoforsl)
    } else if (field === 'datovedt') {
      return this.writeDateItem('Dato vedtaget', props.datovedt)
    } else if (field === 'datoikraft') {
      return this.writeDateItem('Dato i kraft', props.datoikraft)
    }
    return
  }

  writeDateItem(label, dateItem) {
    let value = 'Ikke angivet'
    let valueformat = "string"

    let mydateItemstring = dateItem ? dateItem.toString() : ''
    //let datestring = dateItem.toString()
    if (this.hasvalue(dateItem)) {

      dateItem += ""
      if (mydateItemstring.length === 8)
        value = dateItem.substr(0, 4) + "-" + dateItem.substr(4, 2) + "-" + dateItem.substr(6, 2)
      if (mydateItemstring.length === 24)
        value = dateItem.substr(0, 4) + "-" + dateItem.substr(5, 2) + "-" + dateItem.substr(8, 2)
      valueformat = "iso-date"
    }
    return {
      type: 'labelvalue',
      label: label,
      value: value,
      valueformat: valueformat
    }
  }

  hasvalue(item) {
    if (typeof item !== 'undefined') {
      if (item !== '' && item !== null)
        return true
    }
    return false
  }

  createZsItem(result, field) {
    let props = result.data.properties
    if (field === 'planid')
      return {
        type: 'labelvalue',
        label: 'Planid',
        value: props.planid
      }
    else if (field === 'kommunenavn')
      return {
        type: 'labelvalue',
        label: 'Kommune',
        value: props.kommunenavn,

      }
    else if (field === 'zonestatus')
      return {
        type: 'labelvalue',
        label: 'Zonestatus',
        value: props.zonestatus

      }
    return
  }
  createKnzItem(result, field) {
    let props = result.data.properties
    if (field === 'gml_id')
      return {
        type: 'labelvalue',
        label: 'GML_ID',
        value: result.id
      }
    else if (field === 'navn')
      return {
        type: 'labelvalue',
        label: 'Navn',
        value: props.navn,

      }
    else if (field === 'region')
      return {
        type: 'labelvalue',
        label: 'Region',
        value: props.regionsnav

      }
    else if (field === 'cpr_noegle')
      return {
        type: 'labelvalue',
        label: 'Cprnøgle',
        value: props.cpr_noegle

      }
    return
  }

  createTpkItem(result, field) {
    let props = result.data.properties
    if (field === 'id')
      return {
        type: 'labelvalue',
        label: 'ID',
        value: result.id
      }
    else if (field === 'hovedstruk')
      return {
        type: 'labelvalue',
        label: 'Hovedstruktur',
        value: props.hovedstruk,

      }
    else if (field === 'navn_spec')
      return {
        type: 'labelvalue',
        label: 'Navn specifik',
        value: props.navn_spec

      }
    else if (field === 'kode')
      return {
        type: 'labelvalue',
        label: 'Kode',
        value: props.kode


      }
    else if (field === 'kortbilag')
      return {
        type: 'labelvalue',
        label: 'Kortbilag',
        value: props.kortbilag
      }
    else if (field === 'dato_ret')
      return {
        type: 'labelvalue',
        label: 'Dato rettet',
        value: props.dato_ret


      }
    return
  }

  createLztItem(result, field) {
    let props = result.data.properties
    if (field === 'id')
      return {
        type: 'labelvalue',
        label: 'Id',
        value: result.id
      }
    else if (field === 'planid')
      return {
        type: 'labelvalue',
        label: 'Planid',
        value: props.planid,

      }
    else if (field === 'komnr')
      return {
        type: 'labelvalue',
        label: 'Kommunekode',
        value: props.komnr

      }
    else if (field === 'kommunenavn')
      return {
        type: 'labelvalue',
        label: 'Kommune',
        value: props.kommunenavn

      }
    else if (field === 'lztnavn')
      return {
        type: 'labelvalue',
        label: 'Landzonetilladelse (navn)',
        value: props.lztnavn

      }
    else if (field === 'lztnr')
      return {
        type: 'labelvalue',
        label: 'Landzonetilladelse (nr)',
        value: props.lztnr

      }
    else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoudloeb') {
      let strDate = "" + props.datoudloeb
      return {
        type: 'labelvalue',
        label: 'Datoudløb',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'doklink')
      return {
        type: 'link',
        label: 'Tilladelsesdokument',
        link: props.doklink,
        linkTitle: props.lztnavn,
        target: '_blank',
        icon: icons.details.link,
      }
    else if (field === 'aktuel')
      return {
        type: 'labelvalue',
        label: 'Aktuel',
        value: props.aktuel ? 'Ja' : 'Nej' 

      }
    else if (field === 'aenanvbyg')
      return {
        type: 'labelvalue',
        label: 'Angivelsen af om landzonetilladelsen omhandler ændret anvendelse af bebyggelse',
        value: props.aenanvbyg ? 'Ja' : 'Nej' 

      }
    else if (field === 'aenanvarea')
      return {
        type: 'labelvalue',
        label: 'Angivelsen af om landzonetilladelsen omhandler ændret anvendelse af areal',
        value: props.aenanvarea ? 'Ja' : 'Nej' 

      }
    else if (field === 'tekanlaeg')
      return {
        type: 'labelvalue',
        label: 'Angivelsen af om landzonetilladelsen omhandler tekniske anlæg',
        value: props.tekanlaeg ? 'Ja' : 'Nej'       
      }
    else if (field === 'andet')
      return {
        type: 'labelvalue',
        label: 'Angivelsen af om landzonetilladelsen omhandler andet',
        value: props.andet ? 'Ja' : 'Nej' 

      }
    else if (field === 'status') {
      return {
        type: 'labelvalue',
        label: 'Planstatus',
        value: this.getStatusTekstFraKode(props.planstatus)
  
      }
    } else if (field === 'matrbetegn') {
      return {
        type: 'labelvalue',
        label: 'Matriker',
        value: props.matrbetegn
  
      }
    }
      
    return
  }

  createRpItem(result, field) {
    let props = result.data.properties
    if (field === 'amt')
      return {
        type: 'labelvalue',
        label: 'Amt',
        value: result.searcher.fixString(props.amtnavn)
      }
    else if (field === 'doklink')
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: result.searcher.fixString(props.dokumentna),
        target: '_blank',
        icon: icons.details.link,
      }
    else if (field === 'dokumentnavn')
      return {
        type: 'labelvalue',
        label: 'Dokumentnavn',
        value: result.searcher.fixString(props.dokumentna)

      }
    return
  }

  async createDoItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'lp_plannavn') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan',
        value: props.lp_plannavn

      }
    } else if (field === 'lokplan_id') {
      let planResult = await result.searcher.get(props.lokplan_id, 'vedtagetlokalplan')
      return {
        type: 'result',
        result: planResult
      }
    } else if (field === 'lp_plannr') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan nr',
        value: props.lp_plannr
      }
    } else if (field === 'delnr') {
      return {
        type: 'labelvalue',
        label: 'Delnr',
        value: props.delnr

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'sforhold') {
      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold
      }
    } else if (field === 'notomranv') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }

  async createBfItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        icon: icons.details.link,

      }
    } else if (field === 'lp_plannavn') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan',
        value: props.lp_plannavn

      }
    } else if (field === 'lokplan_id') {
      let planResult = await result.searcher.get(props.lokplan_id, 'vedtagetlokalplan')
      return {
        type: 'result',
        result: planResult
      }
    } else if (field === 'lp_plannr') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan nr',
        value: props.lp_plannr
      }
    } else if (field === 'delnr') {
      return {
        type: 'labelvalue',
        label: 'Delnr',
        value: props.delnr

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'sforhold') {
      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold
      }
    } else if (field === 'notomranv') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }
  createAnvendelseKpl(properties) {
    let p = properties
    let textarea = ''
    if (typeof p.notomranv !== 'undefined' && p.notomranv !== null && p.notomranv !== '') {
      if (typeof p.notomranv !== 'undefined' && p.notomranv !== null && p.notomranv !== '') {
        textarea += p.notomranv
      }
      if (typeof p.notanvgen !== 'undefined' && p.notanvgen !== null && p.notanvgen !== '') {
        textarea += p.notanvgen
      }
    } else
      return

    return {
      type: 'labelvalue',
      label: 'Anvendelse',
      value: textarea
    }

  }
  createBebyggelseKpl(properties) {
    let p = properties
    let textarea = ''
    if (typeof p.notbebygom !== 'undefined' && p.notbebygom !== null && p.notbebygom !== '') {
      textarea += p.notbebygom
      textarea += '\n'
    }
    if (typeof p.bebygpct !== 'undefined' && p.bebygpct !== null && p.bebygpct !== 0) {
      let bp = p.bebygpct.toString().replace('.', ',')
      let bpa = {
        '1': 'området som helhed',
        '2': 'den enkelte ejendom',
        '3': 'den enkelte grund',
        '4': 'det enkelte jordstykke'
      }
      let bebygpctaf = (p.bebygpctaf !== null ? ' for ' + bpa[p.bebygpctaf] : '')
      textarea += `Bebyggelsesprocenten ${bebygpctaf} må ikke overstige ${bp}.`
      textarea += '\n'
    }
    if (typeof p.maxbygnhjd !== 'undefined' && p.maxbygnhjd !== null && p.maxbygnhjd !== 0) {
      let mh = p.maxbygnhjd.toString().replace('.', ',')
      textarea += `Bebyggelseshøjden må ikke overstige ${mh} m.`
      textarea += '\n'
    }
    if (typeof p.maxetager !== 'undefined' && p.maxetager === 1) {
      textarea += `Bebyggelsen må ikke opføres i mere end 1 etage.`
      textarea += '\n'
    }
    if (typeof p.maxetager !== 'undefined' && p.maxetager > 1) {
      let me = p.maxetager.toString().replace('.', ',')
      textarea += `Bebyggelsen må ikke opføres i mere end ${me} etager.`
      textarea += '\n'
    }
    if (typeof p.m3_m2 !== 'undefined' && p.m3_m2 !== null && p.m3_m2 !== 0) {
      textarea += `Bygningernes rumfang må ikke overstige ${p.m3_m2} m3 pr. m2 grundareal. `
      textarea += '\n'
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Bebyggelse',
      value: textarea
    }

  }
  createTrafikParkeringKpl(properties) {
    let p = properties
    let textarea = ''

    if (typeof p.notinfras !== 'undefined' && p.notinfras !== null && p.notinfras !== '') {
      textarea += p.notinfras
      textarea += '\n'
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Trafik og Parkering',
      value: textarea
    }

  }
  createZoneBestemmelserKpl(properties) {
    let p = properties
    let textarea = ''

    if (typeof p.notzone !== 'undefined' && p.notzone !== null && p.notzone !== '') {
      textarea += p.notzone
      textarea += '\n'
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Zonebestemmelser',
      value: textarea
    }

  }
  createMiljoeKpl(properties) {
    let p = properties
    let textarea = ''

    if ((typeof p.notmiljo !== 'undefined' && p.notmiljo !== null && p.notmiljo !== '') || (typeof p.minmiljo !== 'undefined' && p.minmiljo !== null && p.minmiljo !== 0) || (typeof p.maxmiljo !== 'undefined' && p.maxmiljo !== null && p.maxmiljo !== 0)) {
      let miljotester = [
        '',
        ', hvilket svarer til virksomheder og anlæg, der ikke giver anledning til nævneværdig påvirkning af omgivelserne. Miljømæssig afstand til boliger ikke nødvendig. ',
        ', hvilket svarer til virksomheder og anlæg med \'ubetydelig\' påvirkning af de nærmeste omgivelser. Afstand til boliger bør være ca. 20 m. ',
        ', hvilket svarer til virksomheder og anlæg, der fra udendørsaktiviteter (herunder transport) giver anledning til mindre påvirkning af omgivelserne: \'Uvæsentlig forurening\'. Afstand til boliger bør være ca. 50 m. ',
        ', hvilket svarer til virksomheder og anlæg, der kan medføre \'mindre væsentlig forurening\'. Afstand til boliger bør være ca. 100 m. ',
        ', hvilket svarer til virksomheder og anlæg, der kan medføre \'væsentlig forurening\'. Afstand til boliger bør være ca. 150 m. ',
        ', hvilket svarer til virksomheder og anlæg,der kan medføre \'betydelig forurening\'. Afstand til boliger bør være ca. 300m. ',
        ', hvilket svarer til virksomheder og anlæg, der kan give anledning til \'omfattende forurening\' og derfor stiller særlige beliggenhedskrav. Kan f.eks. omfatte medicinal- og pesticidproduktion, kraftværker og svinefabrikker. Afstand til boliger bør være mindst 500 m. '
      ]
      if (typeof p.minmiljo !== 'undefined' && p.minmiljo !== null && p.minmiljo !== 0) {
        textarea += `Virksomheder og anlæg skal minimum have miljøklasse: ${p.minmiljo + miljotester[p.minmiljo]}`
        textarea += '\n'
      }
      if (typeof p.maxmiljo !== 'undefined' && p.maxmiljo !== null && p.maxmiljo !== 0) {
        textarea += `Virksomheder og anlæg må maksimum have miljøklasse: ${p.maxmiljo + miljotester[p.maxmiljo]}`
        textarea += '\n'
      }
      if (typeof p.notmiljo !== 'undefined' && p.notmiljo !== null && p.notmiljo !== '') {
        textarea += p.notmiljo
        textarea += '\n'
      }
    } else
      return

    return {
      type: 'labelvalue',
      label: 'Miljø',
      value: textarea
    }

  }
  createAndreforholdKpl(properties) {
    let p = properties
    let textarea = ''

    if ((typeof p.notat !== 'undefined' && p.notat !== null && p.notat !== '') || (typeof p.notlokalp !== 'undefined' && p.notlokalp !== null && p.notlokalp !== '') || (typeof p.notophold !== 'undefined' && p.notophold !== null && p.notophold !== '')) {
      if (typeof p.notlokalp !== 'undefined' && p.notlokalp !== null && p.notlokalp !== '') {
        textarea += p.notlokalp
        textarea += '\n'
      }
      if (typeof p.notat !== 'undefined' && p.notat !== null && p.notat !== '') {
        textarea += p.notat
        textarea += '\n'
      }
      if (typeof p.notophold !== 'undefined' && p.notophold !== null && p.notophold !== '') {
        textarea += p.notophold
        textarea += '\n'
      }
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Andre forhold',
      value: textarea
    }

  }
  opdateretKpt(properties) {
    let p = properties
    let dato = (p.datoikraft).toString()
    let y = dato.substr(0, 4)
    let m = dato.substr(4, 2)
    let d = dato.substr(6, 2)
    return {
      type: 'labelvalue',
      label: 'Opdateret kommuneplanramme',
      value: `Kommuneplanramme er opdateret og ændret i henhold til kommuneplantillæg gældende fra ${d}.${m}.${y}.`
    }
  }
  createKplItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'opdateret' && result.typeId === 'kommuneplantillaeg')
      return this.opdateretKpt(props)

    if (field === 'kommuneplan_anvendelse') {
      return this.createAnvendelseKpl(props)
    }
    if (field === 'kommuneplan_bebyggelse') {
      return this.createBebyggelseKpl(props)
    }
    if (field === 'kommuneplan_trafik_parkering') {
      return this.createTrafikParkeringKpl(props)
    }
    if (field === 'kommuneplan_zonebestemmelser') {
      return this.createZoneBestemmelserKpl(props)
    }
    if (field === 'kommuneplan_miljoe') {
      return this.createMiljoeKpl(props)
    }
    if (field === 'kommuneplan_andreforhold') {
      return this.createAndreforholdKpl(props)
    }
    if (field === 'anvendelsegenerel') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse generel',
        value: props.anvendelsegenerel || 'Anvendelse ikke reguleret'
      }
    } else if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'plannavn') {
      return {
        type: 'labelvalue',
        label: 'Plannavn',
        value: props.plannavn

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'plannr') {
      return {
        type: 'labelvalue',
        label: 'Plannr',
        value: props.plannr || 'ingen plannr'
      }
    } else if (field === 'sforhold') {
      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold || 'Ingen særlige forhold angivet'
      }
    } else if (field === 'notomranv') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv || 'Ingen rammetekst angivet'
      }

    } else if (field === 'notanvgen') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst om generelle anvendelsesbestemmelser',
        value: props.notanvgen || 'Ingen rammetekst angivet'
      }

    } else if (field === 'notbebygom') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst om bebyggelsesomfang og -udformning',
        value: props.notbebygom || 'Ingen angivet'
      }

    } else if (field === 'notmiljo') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst om miljøforhold',
        value: props.notmiljo || 'Ingen angivet'
      }

    } else if (field === 'notinfras') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst om infrastruktur',
        value: props.notinfras || 'Ingen angivet'
      }

    } else if (field === 'notzone') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst om zonestatus',
        value: props.notzone || 'Ingen angivet'
      }

    } else if (field === 'notlokalp') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst om lokalplaner indenfor rammen',
        value: props.notlokalp || 'Ingen angivet'
      }

    } else if (field === 'notat') {
      return {
        type: 'labelvalue',
        label: 'Notatfelt',
        value: props.notat || 'Ingen angivet'
      }

    } else if (field === 'minuds') {
      return {
        type: 'labelvalue',
        label: 'Den mindste tilladte udstykningsstørrelse for den specifikke anvendelse',
        value: props.minuds || 'Ingen angivet'
      }

    } else if (field === 'bebygpct') {
      let value = props.bebygpct ? `${props.bebygpct} (${this.getBebygMetodeFraKode(props.bebygpctaf)})`: 'Ikke angivet'      
      return {
        type: 'labelvalue',
        label: 'Max. bebyggelsesprocent (%)',
        value: value
      }

    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoaflyst') {
      return this.writeDateItem('Dato aflyst', props.datoaflyst)
    } else if (field === 'datostart') {
      return this.writeDateItem('Dato start, offentlighedsperiode', props.datostart)
    } else if (field === 'datoslut') {
      return this.writeDateItem('Dato slut, offentlighedsperiode', props.datoslut)
    } else if (field === 'saflysdato') {
      return this.writeDateItem('Saflysdato', props.saflysdato)
    } else if (field === 'datooprt') {
      return this.writeDateItem('Dato oprettet', props.datooprt)
    } else if (field === 'datoopdt') {
      return this.writeDateItem('Dato opdateret', props.datoopdt)
    } else if (field === 'planstatus') {
      let planstatus = ''
      if (props.planstatus === 'V')
        planstatus = 'Vedtaget' //kan ikke finde kodeliste
      else
        planstatus = props.planstatus
      return {
        type: 'labelvalue',
        label: 'Planstatus',
        value: planstatus || 'Ingen planstatus angivet'
      }
    } else if (field === 'komnr') {
      return {
        type: 'labelvalue',
        label: 'Kommunekode',
        value: props.komnr || 'Ingen kommnekode'
      }
    } else if (field === 'distrikt') {
      return {
        type: 'labelvalue',
        label: 'Distrikt',
        value: props.distrikt || 'Intet distrikt angivet'
      }
    } else if (field === 'planid') {
      return {
        type: 'labelvalue',
        label: 'Planid',
        value: props.planid || 'Intet planid angivet'
      }

    } else if (field === 'objektkode') {
      return {
        type: 'labelvalue',
        label: 'Objektkode',
        value: props.objektkode || 'Ingen objektkode angivet'
      }
    } else if (field === 'komplan_id') {
      return {
        type: 'labelvalue',
        label: 'PlanID for kommuneplan som rammen er knyttet til',
        value: props.komplan_id || 'Ingen kommuneplan'
      }
    } else if (field === 'komtil_id') {
      return {
        type: 'labelvalue',
        label: 'PlanID for kommuneplantillæg som rammen er knyttet til',
        value: props.komtil_id || 'Ingen kommuneplan'
      }
    } else if (field === 'fzone') {
      return {
        type: 'labelvalue',
        label: 'Fremtidig zonestatus',
        value: this.getFremtidigZoneStatusTekstFraKode(props.fzone) || 'Ikke angivet'
      }
    } else if (field === 'zone') {
      return {
        type: 'labelvalue',
        label: 'Zonestatus',
        value: this.getZoneStatusTekstFraKode(props.zone) || 'Ikke angivet'
      }
    } else if (field === 'minmiljo') {
      return {
        type: 'labelvalue',
        label: 'Min. tilladte miljøklasse',
        value: props.minmiljo || 'Ikke angivet'
      }
    } else if (field === 'maxmiljo') {
      return {
        type: 'labelvalue',
        label: 'Max. tilladte miljøklasse',
        value: props.maxmiljo || 'Ikke angivet'
      }
    } else if (field === 'bebygpctaf') {
      return {
        type: 'labelvalue',
        label: 'Beregningsmetode for bebyggelsesprocent',
        value: this.getBebygMetodeFraKode(props.bebygpctaf)
      }
    } else if (field === 'eareal') {
      return {
        type: 'labelvalue',
        label: 'Max. etageareal',
        value: props.eareal || 'Ikke angivet'
      }
    } else if (field === 'm3_m2') {
      return {
        type: 'labelvalue',
        label: 'Max. bebyggelsesrumfang (m3/m2)',
        value: props.m3_m2 || 'Ikke angivet'
      }
    } else if (field === 'm3_m2h') {
      return {
        type: 'labelvalue',
        label: 'Max. andel af grundarealet der må bebygges (%)',
        value: props.m3_m2h || 'Ikke angivet'
      }
    } else if (field === 'maxbygnhjd') {
      return {
        type: 'labelvalue',
        label: 'Max. bygningshøjde',
        value: props.maxbygnhjd || 'Ikke angivet'
      }
    } else if (field === 'maxetager') {
      return {
        type: 'labelvalue',
        label: 'Max. antal etager',
        value: props.maxetager || 'Ikke angivet'
      }
    } else if (field === 'bebygpctar') {
      return {
        type: 'labelvalue',
        label: 'Den højeste andel (%) af grundarealet som må bebygges',
        value: props.bebygpctar || 'Ikke angivet'
      }
    } else if (field === 'iomfangreg') {
      return {
        type: 'labelvalue',
        label: 'Bebyggelsesomfang',
        value: props.iomfangreg ? 'Bebyggelsesomfanget reguleres et andet sted (se evt. lokalplandelområde)' : 'Planen regulerer byggelsesomfang'
      }
    } else if (field === 'iudstykreg') {
      return {
        type: 'labelvalue',
        label: 'Angiver om planen IKKE regulerer udstykning',
        value: props.iudstykreg ? 'Ja' : 'Nej'
      }
    } else if (field === 'udstykforbud') {
      return {
        type: 'labelvalue',
        label: 'Udstykningsforbud',
        value: props.udstykforbud || 'Ikke angivet'
      }
    }


    return
  }
  async createLpItem(result, field, anvspec) {

    let props = result.data.properties
    if (field === 'anvendelsegenerel') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse generel',
        value: props.anvendelsegenerel || 'Anvendelse ikke reguleret'
      }
    } else if (field === 'ianvreg') {
      return {
        type: 'labelvalue',
        label: 'Planen regulerer ikke anvendelse',
        value: props.ianvreg ? 'Ja' : 'Nej' || 'Ikke angivet'

      }
    } else if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        //linkTitle: result.searcher.fixString(props.plannavn),
        linkTitle: 'Hent PDF',
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'plannavn') {
      return {
        type: 'labelvalue',
        label: 'Plannavn',
        value: result.searcher.fixString(props.plannavn)

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'byggefelter_result') {
      let queryResult = await result.searcher.getByggefeltByPlanId(result.id)
      let results = queryResult.getResults()
      if (results.length > 0) {
        let header = results.length === 1 ? "Byggefelt" : "Byggefelter"
        let detailItemsList = new DetailItemsList({
          itemType: "result",
          header: header,
          isHomogenous: true,
          name: "byggefelter_liste"
        })
        for (let result of results)
          detailItemsList.append({
            type: "result",
            result: result
          })
        return detailItemsList.asItem()
      } else {
        let detailItemsList = new DetailItemsList({
          itemType: "labelvalue",
          header: 'Byggefelter',
          isHomogenous: true,
          name: "byggefelter_ingen"
        })
        detailItemsList.append({
          type: 'labelvalue',
          value: "Ingen byggefelter fundet"
        })
        return detailItemsList.asItem()
      }
    } else if (field === 'delomraader_result') {
      let queryResult = await result.searcher.getDelomraadeByPlanId(result.id)
      let results = queryResult.getResults()
      if (results.length > 0) {
        let header = results.length === 1 ? "Delområde" : "Delområder"
        let detailItemsList = new DetailItemsList({
          itemType: "result",
          header: header,
          isHomogenous: true,
          name: "delomraader_liste"
        })
        for (let result of results)
          detailItemsList.append({
            type: "result",
            result: result
          })
        return detailItemsList.asItem()
      } else {
        let detailItemsList = new DetailItemsList({
          itemType: "labelvalue",
          header: 'Delområder',
          isHomogenous: true,
          name: "delomraader_ingen"
        })
        detailItemsList.append({
          type: 'labelvalue',
          value: "Ingen delområder fundet"
        })
        return detailItemsList.asItem()
      }
    } else if (field === 'plannr') {
      return {
        type: 'labelvalue',
        label: 'Plannr',
        value: props.plannr || 'ingen plannr'
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'sforhold') {

      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold || 'Ingen'

      }
    } else if (field === 'iomfangreg') {
      return {
        type: 'labelvalue',
        label: 'Bebyggelsesomfang',
        value: props.iomfangreg ? 'Planen regulerer IKKE byggelsesomfang' : 'Planen regulerer byggelsesomfang'
      }
    } else if (field === 'bebygpct') {
      let value = props.bebygpct ? `${props.bebygpct} (${this.getBebygMetodeFraKode(props.bebygpctaf)})`: 'Ikke angivet'      
      return {
        type: 'labelvalue',
        label: 'Max. bebyggelsesprocent (%)',
        value: value
      }
    } else if (field === 'bebygpctaf') {
      return {
        type: 'labelvalue',
        label: 'Beregningsmetode for bebyggelsesprocent',
        value: this.getBebygMetodeFraKode(props.bebygpctaf)
      }
    } else if (field === 'bebygpctar') {
      return {
        type: 'labelvalue',
        label: 'Max. andel af grundarealet der må bebygges (%)',
        value: props.bebygpctar || 'Ikke angivet'
      }
    } else if (field === 'eareal') {
      return {
        type: 'labelvalue',
        label: 'Max. etageareal (m2)',
        value: props.eareal || 'Ikke angivet'
      }
    } else if (field === 'earealh') {
      return {
        type: 'labelvalue',
        label: 'Etagearealet er angivet for',
        value: this.getBebygMetodeFraKode(props.earealh)
      }
    } else if (field === 'm3_m2') {
      return {
        type: 'labelvalue',
        label: 'Max. bebyggelsesrumfang (m3/m2)',
        value: props.m3_m2 || 'Ikke reguleret'
      }
    } else if (field === 'm3_m2h') {
      return {
        type: 'labelvalue',
        label: 'Beregningsmetode for bebyggelsesrumfang',
        value: props.m3_m2h || 'Ikke angivet'
      }
    } else if (field === 'maxetager') {
      return {
        type: 'labelvalue',
        label: 'Max. antal etager',
        value: props.maxetager || 'Ikke reguleret'
      }
    } else if (field === 'minmiljo') {
      return {
        type: 'labelvalue',
        label: 'Min. tilladte miljøklasse',
        value: props.minmiljo || 'Ikke reguleret'
      }
    } else if (field === 'maxmiljo') {
      return {
        type: 'labelvalue',
        label: 'Max. tilladte miljøklasse',
        value: props.maxmiljo || 'Ikke reguleret'
      }
    } else if (field === 'iudstykreg') {
      return {
        type: 'labelvalue',
        label: 'Udstykning',
        value: props.iudstykreg ? 'Udstykning reguleres et andet sted (se evt. lokalplandelområde)' : 'Planen regulerer udstykning'
      }
    } else if (field === 'maxbygnhjd') {
      return {
        type: 'labelvalue',
        label: 'Max. tilladte bygningshøjde',
        value: props.maxbygnhjd || 'Ikke reguleret'
      }
    } else if (field === 'megawatt') {
      return {
        type: 'labelvalue',
        label: 'Planlagt megawatt (vindmøller)',
        value: props.megawatt || 'Ikke angivet'
      }
    } else if (field === 'minuds') {
      return {
        type: 'labelvalue',
        label: 'Den mindste tilladte grundstørrelse ved udstykning',
        value: props.minuds || 'Ikke reguleret'
      }
    } else if (field === 'udsbefor') {
      return {
        type: 'labelvalue',
        label: 'Forbyder udstykning i planområdet.',
        value: props.udsbefor ? 'Ja' : 'Nej'
      }
    } else if (field === 'distrikt') {
      return {
        type: 'labelvalue',
        label: 'Distrikt',
        value: props.distrikt || 'Ikke angivet'

      }
    } else if (field === 'zone') {
      return {
        type: 'labelvalue',
        label: 'Zonestatus',
        value: this.getZoneStatusTekstFraKode(props.zone) || 'Ikke angivet'
      }
    } else if (field === 'izonereg') {
      return {
        type: 'labelvalue',
        label: 'Zonestatus  er ikke entydig byzone/landzone',
        value: props.izonereg ? 'Ja' : 'Nej' || 'Ikke angivet'
      }
    } else if (field === 'abestem1') {
      return {
        type: 'labelvalue',
        label: 'Bestemmelser om skilte eller facader',
        value: (props.abestem1 == 4150) ? 'Ja' : 'Nej'
      }
    } else if (field === 'abestem2') {
      return {
        type: 'labelvalue',
        label: 'Bevaringsbestemmelser.',
        value: (props.abestem2 == 4160) ? 'Ja' : 'Nej'
      }
    } else if (field === 'bevarbest') {
      return {
        type: 'labelvalue',
        label: 'Bevaringsbestemmelser',
        value: props.bevarbest || 'Ikke angivet'
      }
    } else if (field === 'kompleks') {
      return {
        type: 'labelvalue',
        label: 'Kompleks plan (kan ikke registreres i Plansystem)',
        value: props.kompleks ? props.kbeskriv : 'Nej'
      }
    }
    return
  }
  getFremtidigZoneStatusTekstFraKode(kode) {
    const zonekoder = {
      "1": {
        "tekst": "Fremtidig byzone indenfor kommuneplanperioden"
      },
      "2": {
        "tekst": "Potentielt fremtidigt område til byzone"
      },
      "3": {
        "tekst": "Fremtidig sommerhusområde indenfor kommuneplanperioden"
      },
      "4": {
        "tekst": "Potentielt fremtidigt sommerhusområde"
      },
      "5": {
        "tekst": "Fremtidig tilbageførelse af byzone til landzone"
      },
      "6": {
        "tekst": "Fremtidig tilbageførelse af sommerhusområde til landzone"
      }

    }
    if (zonekoder[kode])
      return zonekoder[kode]["tekst"]
    else
      return kode || 'Ikke angivet'
  }
  getZoneStatusTekstFraKode(kode) {
    const zonekoder = {
      "1": {
        "tekst": "Byzone"
      },
      "2": {
        "tekst": "Landzone"
      },
      "3": {
        "tekst": "Sommerhusområde"
      },
      "4": {
        "tekst": "Byzone og landzone"
      },
      "5": {
        "tekst": "Sommerhusområde og landzone"
      },
      "6": {
        "tekst": "By og sommerhusområde"
      },
      "7": {
        "tekst": "Byzone, landzone og sommerhusområde"
      }

    }
    if (zonekoder[kode])
      return zonekoder[kode]["tekst"]
    else
      return kode
  }
  getBebygMetodeFraKode(kode) {
    const metodekoder = {
      "1": {
        "tekst": "Området som helhed"
      },
      "2": {
        "tekst": "Den enkelte ejendom"
      },
      "3": {
        "tekst": "Den enkelte grund"
      },
      "4": {
        "tekst": "Det enkelte jordstykke"
      }

    }
    if (metodekoder[kode])
      return metodekoder[kode]["tekst"]
    else
      return kode || 'Ikke specificeret'
  }
  getStatusTekstFraKode (kode) {
    const statuskoder = {
      "KF": {tekst:"Kladde til forslag"},
      "F":  {tekst:"Forslag"},
      "K":  {tekst:"Kladde"},
      "KV": {tekst:"Kladde til vedtagelse"},
      "V":  {tekst:"Vedtaget"},
      "A":  {tekst:"Aflyst"},     
    }
    if (statuskoder[kode])
      return statuskoder[kode]["tekst"]
    else
      return kode || 'Ikke specificeret'
  }
}

