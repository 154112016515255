import {fetch2} from "../../utils.js"
import Cache from "../../util/Cache.js"
import {Ressource} from "../../util/Ressources.js"
/**
 * @sspath Septima.Search.Tingbog
 */
export default class Fetcher {
  /**
   * @param {Object} options
   * @param {string} [options.token] Bearer token til tingbogs-api
   * @param {string} [options.endpoint=https://tinglysning-api.septima.dk]
   **/
  constructor(options = {}) {
    this.token = "2axdUaadjfeh5Hf6EPXfca"
    if (options.token)
      this.token = options.token

    this.endpoint = "https://tinglysning-api.septima.dk"
    if (options.endpoint)
      this.endpoint = options.endpoint

    this.ejendomSummariskCache = new Cache({ttl: 10000, maxEntries: 40})
  }
  
  async getEjendomSummarisk(bfeNummer) {
    let ejendomSummarisk = this.ejendomSummariskCache.get(bfeNummer)
    if (!ejendomSummarisk) {
      //https://tinglysning-api.septima.dk/TinglysningApi?BFEs=10035005&UdeladOgsaaLystpaa=true&ServitutDokumentSomLink=true
      let reply = await this.get("tinglysningapi", {BFEs: bfeNummer, UdeladOgsaaLystpaa: true, ServitutDokumentSomLink: true})
      if (reply.length > 0 && reply[0].Items.length > 0) {
        ejendomSummarisk = reply[0].Items[0]
        this.ejendomSummariskCache.set(bfeNummer, ejendomSummarisk)
        return ejendomSummarisk
      }
    }
    return ejendomSummarisk
  }
  
  // eslint-disable-next-line no-unused-vars
  async getMatrikelNummerItemsForServitut(bfeNummer, dokumentIdentifikator) {
    let ejendomSummarisk = await this.getEjendomSummarisk(bfeNummer)
    let ejdUuid = ejendomSummarisk.EjdBasis.uuid
    //Jordstykker for en servitut: https://tinglysning-api.septima.dk/HentOgsaaLystPaa?dokuuid=3abdce37-5bec-44b2-af8c-3930cb0dc81a&EjdUuid=80e2f345-4c7f-49f1-9d56-80eba7c00719
    let reply = await this.get("HentOgsaaLystPaa", {dokuuid: dokumentIdentifikator, EjdUuid: ejdUuid})
    if (reply.items)
      return reply.items
    else
      return []
  }

  async get(target, params) {
    let url = `${this.endpoint}/${target}`
    let fetchOptions = this.getRequestOptions(params)
    return await fetch2(url, fetchOptions)
  }
  
  createRessource(url, params = null) {
    return Ressource.createFetch2Ressource(url, this.getRequestOptions(params))    
  }

  getRequestOptions(params) {
    let fetchOptions = {}
    if (params)
      fetchOptions.data = params
    if (this.token)
      fetchOptions.Authorization =  {Bearer: {token: this.token}}
    return fetchOptions
  }

}