/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import DataApiFetcher from "../data-api/Fetcher.js"
import GeoSearch from "../geosearch/GeoSearch.js"
import icons from "../../resources/icons.js"
import VurderingsHandler from "./VurderingsHandler.js"
import { fetch2 } from "../../utils.js"
import { getWKTParser } from "../../util/getWKTParser.js"

/**
 * Viser info for ejendom
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.EjendomsInfoProvider
 * @sspath Septima.Search.Datafordeler
 * **/
export default class EjendomsInfoProvider extends DetailsHandlerDef {

  /**
   * @param {Object} options
   * @param {String[]} [options.sections=["bfe_beskrivelse", "bfe_ejere", "bfe_vurdering", "bfe_forsyning", "bfe_bygninger", "bfe_boliger_virksomheder", "sfe_arealer", "ejl_hovedejendom", "energimaerke", "bfg_gremmedgrund","sfe_bfg"]]
   * @param {String[]} [options.exclude=[]]
   * @param {String[]} [options.dataApiFetcher]
   * @param {String[]} [options.ejerAnon=false]
   * @param {String[]} [options.showHistory=false]
   * @param {String[]} [options.showBeskyttelse=false]
   * @param {String[]} [options.dawaSearcher]
   * @param {String[]} [options.bygningsSearcher]
   **/
  constructor(options = {}) {

    let defaultOptions = {
      buttonText: "",
      ejerAnon: false,
      showBeskyttelse: false,
      showHistory: false
    }

    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      let source = result.source
      let typeId = result.typeId
      return (source === "DAF" && (typeId === "bfg" || typeId === "sfe" || typeId === "ejl" || typeId === "samvurdering"))
    }

    this.wktParser = getWKTParser()
    if (options.dataApiFetcher)
      this.dataApiFetcher = options.dataApiFetcher
    else
      this.dataApiFetcher = new DataApiFetcher()

    this.ejerAnon = options.ejerAnon
    this.showBeskyttelse = options.showBeskyttelse
    this.showHistory= options.showHistory

    if (typeof options.dawasearcher !== 'undefined')
      this.dawaSearcher = options.dawasearcher

    this.geoSearcher = new GeoSearch({})

    if (typeof options.bygningsSearcher !== 'undefined')
      this.bygningsSearcher = options.bygningsSearcher
  
    this.sections = ["bfe_beskrivelse", "bfe_ejere", "bfe_vurdering", "bfe_forsyning", "bfe_bygninger", "energimaerke", "ejl_hovedejendom", "ejl_lod", "bfg_gremmedgrund", "sfe_bfg"] //"bfe_boliger_virksomheder", "sfe_arealer"
    if (typeof options.sections !== 'undefined')
      this.sections = options.sections

    if (typeof options.exclude !== 'undefined') {
      if (Array.isArray(options.exclude)) {
        for (let sectionToExclude of options.exclude) {
          if (this.sections.indexOf(sectionToExclude) > -1)
            this.sections.splice(this.sections.indexOf(sectionToExclude), 1)
        }
      } else {
        if (this.sections.indexOf(options.exclude) > -1)
          this.sections.splice(this.sections.indexOf(options.exclude), 1)

      }
    }

  }
  getbuttonText(result) {
    if (this.buttonText != '') {
      return this.buttonText
    } else {
      let tabtext = 'Om ejendommen'
      if (result.typeId === "bfg")
        tabtext = 'Om bygningen på fremmed grund'
      else if (result.typeId === "ejl")
        tabtext = 'Om ejerlejligheden'
      else if (result.typeId === "samvurdering")
        tabtext = 'Om samvurderingen'
      return tabtext
    }
  }
  async myHandler(result, logger) {
    let source = result.source
    let typeId = result.typeId
    if (source === "DAF") {
      if (typeId === "bfg" || typeId === "sfe" || typeId === "ejl")
        return await this.getBfeDetails(result, logger)
      else
        return await this.getSamvurderingsDetails(result)
    }
  }

  doSection(section) {
    return this.sections.indexOf(section) > -1
  }

  async getBfeDetails(bfeResult, logger) {
    let nothing = Promise.resolve([])
    try {
      let typeId = bfeResult.typeId

      let beskrivelsesItemsPromise = this.doSection("bfe_beskrivelse") ? this.getBfeBeskrivelseItems(bfeResult) : nothing
      let ejerInfoItemsPromise = this.doSection("bfe_ejere") ? this.getBfeEjerInfoItems(bfeResult, logger) : nothing
      let vurderingsItemsPromise = this.doSection("bfe_vurdering") ? this.getBfeVurderingsItems(bfeResult) : nothing
      let getEnergiMaerkeSectionPromise = this.doSection("energimaerke") ? this.getEnergiMaerkeSection(bfeResult) : nothing

      if (typeId === "sfe") {
        let forsyningsItemsPromise = this.doSection("bfe_forsyning") ? this.getBfeForsyningsItems(bfeResult) : nothing
        let arealerItemsPromise = this.doSection("sfe_arealer") ? this.getArealerItems(bfeResult) : nothing
        let boligerVirksomhederItemsPromise = this.doSection("bfe_boliger_virksomheder") ? this.getBoligerVirksomhederItems(bfeResult) : nothing
        let bfgItemsPromise = this.doSection("sfe_bfg") ? this.getBygningPaaFremmedgrundItems(bfeResult) : nothing
        let ejlItemsPromise = this.doSection("sfe_bfg") ? this.getEjerlejlighedItems(bfeResult) : nothing
        return [...await beskrivelsesItemsPromise, ...await forsyningsItemsPromise, ...await arealerItemsPromise, ...await getEnergiMaerkeSectionPromise, ...await ejerInfoItemsPromise, ...await vurderingsItemsPromise, ...await boligerVirksomhederItemsPromise, ...await bfgItemsPromise, ...await ejlItemsPromise]
      }

      if (typeId === "ejl") {
        let bferesponse = bfeResult.data.bfeResponse
        let bfeNummer = bferesponse.properties.BFEnummer
        let ejlResponse = await bfeResult.searcher.fetcher.read("matrikel", "matrikel", "ejerlejlighed", { ELBFEnr: bfeNummer }, this.getLogger())

        let forsyningsItemsPromise = nothing
        let hovedEjendomsItemPromise = nothing
        if (ejlResponse && ejlResponse.features[0].properties.samletFastEjendomBFEnummer) {
          let hovedBfeResult
          hovedBfeResult = await bfeResult.searcher.get(ejlResponse.features[0].properties.samletFastEjendomBFEnummer, "sfe")
          forsyningsItemsPromise = this.doSection("bfe_forsyning") ? this.getBfeForsyningsItems(hovedBfeResult) : nothing
          hovedEjendomsItemPromise = this.doSection("ejl_hovedejendom") ? this.getHovedEjendomsItems(hovedBfeResult) : nothing
        }

        let ejlBygningItemsPromise = this.doSection("bfe_bygninger") ? this.getEjlBygningItems(bfeResult) : nothing
        
        let ejlLodderItemsPromise = this.doSection("ejl_lod") ? this.getEjlLodderItems(ejlResponse) : nothing

        return [...await beskrivelsesItemsPromise, ...await forsyningsItemsPromise, ...await ejerInfoItemsPromise, ...await vurderingsItemsPromise, ...await hovedEjendomsItemPromise, ...await ejlBygningItemsPromise, ...await ejlLodderItemsPromise]
      }

      if (typeId === "bfg") {
        let forsyningsItemsPromise = this.doSection("bfe_forsyning") ? this.getBfgForsyningsItems(bfeResult) : nothing
        let fremmedGrundEjendomsItems = this.doSection("bfg_gremmedgrund") ? this.getFremmedGrundEjendomsItems(bfeResult) : nothing
        let boligerVirksomhederItemsPromise = this.doSection("bfe_boliger_virksomheder") ? this.getBoligerVirksomhederItems(bfeResult) : nothing
        let bygningItemsPromise = this.doSection("bfe_bygninger") ? this.getBfgBygningItems(bfeResult) : nothing
        return [...await beskrivelsesItemsPromise, ...await forsyningsItemsPromise, ...await ejerInfoItemsPromise, ...await vurderingsItemsPromise, ...await fremmedGrundEjendomsItems, ...await boligerVirksomhederItemsPromise, ...await bygningItemsPromise]
      }

    } catch (e) {
      if (logger)
        logger.error(e, "getBfeDetails")
      return ([{
        icon: icons.infoRed,
        type: "labelvalue",
        label: "getBfeDetails",
        value: e.message
      }])
    }
  }

  async getSamvurderingsDetails(samvurderingsResult) {
    let bfeNumre = samvurderingsResult.data.vurderingsResponse.BFEnummerList
    if (bfeNumre.length > 1) {
      let esrBeskrivelsesItems = this.getSamvurderingsBeskrivelsesItems(samvurderingsResult)
      let vurderingsItems = this.getSamvurderingsVurderingsItems(samvurderingsResult)
      let esrBferesultItems = this.getSamvurderingsBferesultItems(samvurderingsResult)
      let ejerInfoItems = this.getSamvurderingsEjerInfoItems(samvurderingsResult)
      return [...await esrBeskrivelsesItems, ...await vurderingsItems, ...await ejerInfoItems, ...await esrBferesultItems]
    }
    return []
  }

  async getEjlBygningItems(bfeResult) {
    if (this.bygningsSearcher) {
      let detailItemsList = new DetailItemsList({
        header: "Ligger i bygning",
        name: "ejl_bygning"
      })
      try {
        let ebrResponse = bfeResult.data.ebrResponse
        let bygId = ebrResponse.features[0].properties.adresse[0].husnummer.adgangTilBygning
        let bygResult = await this.bygningsSearcher.get(bygId, "bygning")
        if (bygResult) {
          detailItemsList.append({
            type: "result",
            result: bygResult
          })
        }
      } catch (e) {
        detailItemsList.append({
          type: "labelvalue",
          label: "Fejl",
          value: e.message
        })
      }
      return [detailItemsList.asItem()]
    } else {
      return []
    }
  }
  async getEjlLodderItems(ejlResponse) {
    let lodder = ejlResponse.features[0].properties.ejerlejlighedslod
    if (lodder.length > 1) {
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Lodder til ejerlejligheden",
        infoItemsHeaders: [
          {type: "labelvalue", label: "Etagebetegnelse"},
          {type: "labelvalue", label: "Beliggenhed"},
          {type: "labelvalue", label: "Lodareal"},
          {type: "labelvalue", label: "Litra"}, 
          {type: "labelvalue", label: "Geografi"}]
      })

      const sorter = function (l1, l2) {
        try {
          let sortvalue = l1.etagebetegnelse.localeCompare(l2.etagebetegnelse)
          if (sortvalue == 0) {
            sortvalue =  l1.lodLitra.localeCompare(l2.lodLitra)
          }
          return sortvalue
        } catch (error) {
          return 0
        }
      }
      lodder =lodder.sort(sorter)
      
      let wktParser = getWKTParser()
      for (let l of lodder) {        
        let areal = wktParser.parse(l.geometri)
        
        detailItemsList.append({
          type: 'labelvalue',
          infoItems: [
            {type: "labelvalue", value: l.etagebetegnelse},
            {type: "labelvalue", value: l.lodBeliggenhedstekst},
            {type: "labelvalue", value: l.lodAreal},
            {type: "labelvalue", value: l.lodLitra},
            {type: "labelvalue", value: areal, valueformat: "geometry"}]
        
        })
      }
     
      return [detailItemsList.asItem()]
      //return [{type: "labelvalue", label: "Lodder", value: "lodder.length"}]
    }else
      return [{type: "labelvalue", label: "Lodder", value: "Ingen lodder fundet"}]    
  }
  async getBfgBygningItems(bfeResult) {
    if (this.bygningsSearcher) {
      let bfeNummer = bfeResult.data.bfeResponse.properties.BFEnummer
      let bygResponse = await bfeResult.searcher.fetcher.read("bbr", "bbr", "bygning", { BFENummer: bfeNummer }, this.getLogger())
      bygResponse = bygResponse.filter(function (b) {
        return b.status === "3" || b.status === "6" || b.status === "12" || b.status === "13"
      })
      if (bygResponse.length > 0) {
        let header = bygResponse.length > 1 ? "Består af bygninger" : "Består af bygning"
        let detailItemsList = new DetailItemsList({
          itemType: "result",
          header: header,
          name: "bfg_bygninger"
        })
        let bygIds = []
        for (let byg of bygResponse) {
          let bygId = byg.id_lokalId
          bygIds.push(bygId)
        }
        let bygResults = await this.bygningsSearcher.gets(bygIds, "bygning")
        bygResults = bygResults.filter(function (b) {
          return b.data.status === "3" || b.data.status === "6" || b.data.status === "12" || b.data.status === "13"
        })
        for (let bygResult of bygResults) {
          detailItemsList.append({
            type: "result",
            result: bygResult
          })
        }
        return [detailItemsList.asItem()]
      }
    }
    return []
  }

  async getFremmedGrundEjendomsItems(bfeResult) {
    let fremmedGrundBfeNummer = bfeResult.data.bfeResponse.properties.samletFastEjendomBFEnummer
    let fremmedGrundResult = await bfeResult.searcher.get(fremmedGrundBfeNummer, "sfe")
    if (fremmedGrundResult) {
      let detailItemsList = new DetailItemsList({
        itemType: "result",
        header: "Ligger på ejendom",
        name: "bfg_sfe"
      })
      detailItemsList.append({
        type: "result",
        result: fremmedGrundResult
      })
      return [detailItemsList.asItem()]
    } else {
      return []
    }
  }

  async getHovedEjendomsItems(hovedBfeResult) {
    let detailItemsList = new DetailItemsList({
      itemType: "result",
      header: "Hovedejejendom",
      name: "ejl_hoved_sfe"
    })
    detailItemsList.append({
      type: "result",
      result: hovedBfeResult
    })
    return [detailItemsList.asItem()]
  }

  async getBfeBeskrivelseItems(bfeResult) {
    //Skal returnere to resultlists

    //Første resultlist
    //https://github.com/Septima/septima-search/issues/348#issuecomment-630161248
    let typeId = bfeResult.typeId
    let bferesponse = bfeResult.data.bfeResponse
    let bfeNummer = bferesponse.properties.BFEnummer
    let ebrResponse = bfeResult.data.ebrResponse
    let ejlResponse

    let beskrivelsesItems = []

    let beskrivelse = ""
    //Todo: test for hoved eller alm
    if (typeId === "sfe") {
      beskrivelse = "Samlet fast ejendom"
      if (bferesponse.properties.hovedejendomOpdeltIEjerlejligheder)
        beskrivelse = "Hovedejendom for ejerlejligheder"
      if (bferesponse.properties.bygningPaaFremmedGrund.length > 0)
        beskrivelse += " med bygninger på fremmed grund"

    } else if (typeId === 'ejl') {
      beskrivelse = "Ejerlejlighed"

      ejlResponse = await bfeResult.searcher.fetcher.read("matrikel", "matrikel", "ejerlejlighed", { ELBFEnr: bfeNummer }, this.getLogger())
      if (ejlResponse && ejlResponse.iBygningPaaFremmedGrund)
        beskrivelse += " beliggende i bygning på fremmed grund"

    } else if (typeId === 'bfg') {
      beskrivelse = "Bygning på fremmed grund"
    }

    let detailItemsList = new DetailItemsList({
      header: beskrivelse,
      name: "bfe_beskrivelse"
    })

    let beliggenhedsItems = await this.getBeliggenhedsItems(ebrResponse, bfeResult)
    for (let item of beliggenhedsItems)
      detailItemsList.append(item)

    if (typeId === "sfe") {
      let jordstykkerTekst = await this.getJordstykkeTekst(bferesponse)
      detailItemsList.append({
        type: "labelvalue",
        label: "Matr. nr.",
        value: jordstykkerTekst
      })
    } else if (typeId === "ejl") {
      let ejerlejlighedsTekst = await this.getEjerlejlighedsTekst(ejlResponse)
      detailItemsList.append({
        type: "labelvalue",
        value: ejerlejlighedsTekst
      })
    }

    beskrivelsesItems.push(detailItemsList.asItem())

    //Anden resultlist
    //https://github.com/Septima/septima-search/issues/348#issuecomment-641255005
    let beskrivelse2
    detailItemsList = new DetailItemsList({
      header: "Beskrivelse",
      name: "bfe_info"
    })

    if (typeId === "sfe") {

      beskrivelse2 = "Samlet fast ejendom"
      if (bferesponse.properties.hovedejendomOpdeltIEjerlejligheder)
        beskrivelse2 = "Hovedejendom for ejerlejligheder"
      if (bferesponse.properties.bygningPaaFremmedGrund.length > 0)
        beskrivelse2 += " med bygninger på fremmed grund"

      detailItemsList.append({
        type: "labelvalue",
        value: beskrivelse2
      })

      if (bferesponse.properties.jordstykke.length > 1) {
        let antalJordstykker = 0
        for (let jordstykke of bferesponse.properties.jordstykke) {
          if (jordstykke.properties.status === "Gældende")
            antalJordstykker += 1
        }
        if (antalJordstykker > 1) {
          detailItemsList.append({
            type: "labelvalue",
            value: "Ejendommen indeholder " + antalJordstykker + " jordstykker"
          })
        }
      }

      if (bferesponse.properties.bygningPaaFremmedGrund.length > 0) {
        detailItemsList.append({
          type: "labelvalue",
          value: "Ejendommen indeholder " + bferesponse.properties.bygningPaaFremmedGrund.length + " bygninger på fremmed grund"
        })
      }

      if (bferesponse.properties.hovedejendomOpdeltIEjerlejligheder) {
        detailItemsList.append({
          type: "labelvalue",
          value: "Ejendommen er hovedejendom for " + bferesponse.properties.ejerlejlighed.length + " ejerlejligheder"
        })
      }

      if (bferesponse.properties.landbrugsnotering) {
        let hovednotering = bferesponse.properties.landbrugsnotering === 'Landbrug uden bygning' ? 'Landbrugsejendom uden beboelse': bferesponse.properties.landbrugsnotering
        detailItemsList.append({
          label: "Hovednotering",
          type: "labelvalue",
          value: hovednotering
        })
      }
    } else if (typeId === 'ejl') {
      beskrivelse2 = "Ejerlejlighed"
      detailItemsList.append({
        type: "labelvalue",
        value: beskrivelse2
      })
    } else if (typeId === 'bfg') {
      beskrivelse2 = "Bygning på fremmed grund"
      detailItemsList.append({
        type: "labelvalue",
        value: beskrivelse2
      })

    }

    beskrivelsesItems.push(detailItemsList.asItem())

    return beskrivelsesItems
  }

  async getBfeVurderingsItems(bfeResult) {
    let handler = new VurderingsHandler({})
    let items = await handler.getLatestBfeVurderingItems(bfeResult)
    return items
  }

  async getEjerlejlighedsTekst(ejlResponse) {
    //'Ejerlejlighed nr.: ' ejerlejlighedsnummer ' - ' samletAreal ' - Fordelingstal: ' fordelingstalTaeller '/' fordelingstalNaevner
    let ejerlejlighedsnummer = ejlResponse.features[0].properties.ejerlejlighedsnummer
    let samletAreal = ejlResponse.features[0].properties.samletAreal
    let fordelingstalTaeller = ejlResponse.features[0].properties.fordelingstalTaeller
    let fordelingstalNaevner = ejlResponse.features[0].properties.fordelingstalNaevner
    return "Ejerlejlighed nr. " + ejerlejlighedsnummer + " - registreret areal: " + samletAreal + " m2 - Fordelingstal: " + fordelingstalTaeller + "/" + fordelingstalNaevner
  }

  async getBfgForsyningsItems(bfeResult) {
    let bfeNummer = bfeResult.data.bfeResponse.properties.BFEnummer
    let bygResponse = await bfeResult.searcher.fetcher.read("bbr", "bbr", "bygning", { BFENummer: bfeNummer }, this.getLogger())
    bygResponse = bygResponse.filter(function (b) {
      return b.status === "3" || b.status === "6" || b.status === "12" || b.status === "13"
    })
    if (bygResponse && bygResponse.length > 0 && bygResponse[0].byg030Vandforsyning) {
      let forsyningsItems = []
      let byg030Vandforsyning = bygResponse[0].byg030Vandforsyning // Eks: 1
      let vandforsyningsTekst = bfeResult.searcher.fetcher.findBbrKode("BygVandforsyning", byg030Vandforsyning)
      let byg031Afløbsforhold = bygResponse[0].byg031Afløbsforhold // Eks: 10
      let afloebsTekst = bfeResult.searcher.fetcher.findBbrKode("Afloebsforhold", byg031Afløbsforhold)
      let detailItemsList = new DetailItemsList({
        header: "Forsyning",
        name: "bfe_forsyning"
      })

      detailItemsList.append({
        type: "labelvalue",
        label: "Vand",
        value: vandforsyningsTekst
      })

      detailItemsList.append({
        type: "labelvalue",
        label: "Afløb",
        value: afloebsTekst
      })

      forsyningsItems.push(detailItemsList.asItem())
      return forsyningsItems

    } else {
      let fremmedGrundBfeNummer = bfeResult.data.bfeResponse.properties.samletFastEjendomBFEnummer
      let fremmedGrundResult = await bfeResult.searcher.get(fremmedGrundBfeNummer, "sfe")
      return await this.getBfeForsyningsItems(fremmedGrundResult)
    }

  }

  async getBfeForsyningsItems(bfeResult) {
    let bfeNummer = bfeResult.data.bfeResponse.properties.BFEnummer
    let forsyningsItems = []

    let grundeResponse = await bfeResult.searcher.fetcher.read("bbr", "bbr", "grund", { BFEnummer: bfeNummer }, this.getLogger())
    grundeResponse = grundeResponse.filter((g) => g.status === "7")
    if (grundeResponse.length > 0) {
      let detailItemsList = new DetailItemsList({
        header: "Forsyning",
        name: "bfe_forsyning"
      })

      let gru009Vandforsyning
      let gru010Afløbsforhold
      for (let grund of grundeResponse) {
        if (typeof gru009Vandforsyning === 'undefined')
          gru009Vandforsyning = grund.gru009Vandforsyning
        if (typeof gru010Afløbsforhold === 'undefined')
          gru010Afløbsforhold = grund.gru010Afløbsforhold
      }

      if (typeof gru009Vandforsyning !== 'undefined') {
        let vandforsyningsTekst = bfeResult.searcher.fetcher.findBbrKode("GruVandforsyning", gru009Vandforsyning)
        detailItemsList.append({
          type: "labelvalue",
          label: "Vand",
          value: vandforsyningsTekst
        })
      }

      if (typeof gru010Afløbsforhold !== 'undefined') {
        let afloebsTekst = bfeResult.searcher.fetcher.findBbrKode("Afloebsforhold", gru010Afløbsforhold)
        detailItemsList.append({
          type: "labelvalue",
          label: "Afløb",
          value: afloebsTekst
        })
      }

      if (typeof gru009Vandforsyning === 'undefined' && typeof gru010Afløbsforhold === 'undefined') {
        detailItemsList.append({
          type: "labelvalue",
          value: "Ingen forsyningsdata"
        })
      }

      forsyningsItems.push(detailItemsList.asItem())
    }

    return forsyningsItems
  }

  // eslint-disable-next-line no-unused-vars
  async getArealerItems(bfeResult) {
    let arealerItems = []
    let detailItemsList = new DetailItemsList({
      header: "Arealer og zonestatus",
      name: "bfe_arealer"
    })

    detailItemsList.append({
      type: "labelvalue",
      label: "TBD",
      value: "TBD"
    })

    arealerItems.push(detailItemsList.asItem())
    return arealerItems
  }

  // eslint-disable-next-line no-unused-vars
  async getBoligerVirksomhederItems(bfeResult) {
    let items = []
    let detailItemsList = new DetailItemsList({
      header: "Boliger og virksomheder",
      name: "bfe_boliger_virksomheder"
    })

    detailItemsList.append({
      type: "labelvalue",
      label: "TBD",
      value: "TBD"
    })

    items.push(detailItemsList.asItem())
    return items
  }

  async getBfeEjerInfoItems(bfeResult, logger) {
    let items = []
    try {
      let ownerDetailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Ejeroplysninger",
        name: "bfe_ejere_liste",
        infoItemsHeaders: [
          { type: "labelvalue", label: "Ejerandel" },
          { type: "labelvalue", label: "Adresse" }]
      })
      if (this.showHistory) {
        ownerDetailItemsList.infoItemsHeaders.push({type: "labelvalue", label: "Status"})
        ownerDetailItemsList.infoItemsHeaders.push({type: "labelvalue", label: "Virkning fra", valueformat: "iso-date" })
      }
      let noOwnerdetailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Ejeroplysninger",
        name: "bfe_ejere_ingen"
      })
      noOwnerdetailItemsList.append({ type: "labelvalue", value: "Ingen ejer fundet" })

      let administratorDetailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Administrator",
        name: "bfe_adm_liste",
        infoItemsHeaders: [{ type: "labelvalue", label: "Adresse" }]
      })

      let ejendom = bfeResult.data.bfeResponse
      let bfeNummer = ejendom.properties.BFEnummer
      try {
        let ejfResponse = await bfeResult.searcher.fetcher.read("Ejerfortegnelse", "Ejerfortegnelsen", "EjerskabMedStamoplysninger", {BFEnr: bfeNummer}, this.getLogger())
        if (!this.showHistory) {
          ejfResponse.features= ejfResponse.features.filter((feature) => feature.properties.status === 'gældende')
        }
        const sortEjere = function (e1, e2) {
          try {
            let sortvalue = e2.properties.virkningFra.localeCompare(e1.properties.virkningFra)
            if (sortvalue == 0) {
              sortvalue = e1.properties.status.localeCompare(e2.properties.status)
            }
            return sortvalue              
          } catch (error) {
            return 0
          }
        }

        ejfResponse.features = ejfResponse.features.sort(sortEjere)

        let hasOwner = ejfResponse && ejfResponse.features && ejfResponse.features.length > 0

        if (hasOwner) {
          let isOneOwnerCVR = false
          let counter = 0
          for (let ejfFeature of ejfResponse.features) {            
            if (ejfFeature.properties.ejendeVirksomhed) {
              isOneOwnerCVR = true             
              if (counter === 0) { // only add link once
                ownerDetailItemsList.infoItemsHeaders.push({type: "link", label: "CVR"})
                counter++
              }
            }
          }
          if (this.showBeskyttelse && !isOneOwnerCVR)
            ownerDetailItemsList.infoItemsHeaders.push({ type: "labelvalue", label: "Beskyttelser" })
          if (this.showBeskyttelse && isOneOwnerCVR)
            ownerDetailItemsList.infoItemsHeaders.push({ type: "labelvalue", label: "Reklamebeskyttelse" })

          for (let ejfFeature of ejfResponse.features) {
            let ejerNavn
            let status = ejfFeature.properties.status
            let virkningfra = ejfFeature.properties.virkningFra
            let cvrNummer
            let reklameBeskyttelse
            let ejerAndelString = ''
            if (ejfFeature.properties.faktiskEjerandel_taeller && ejfFeature.properties.faktiskEjerandel_naevner)
              ejerAndelString = ejfFeature.properties.faktiskEjerandel_taeller + "/" + ejfFeature.properties.faktiskEjerandel_naevner
            let ejerAddresse = await this.getEjfFeatureAdresse(ejfFeature)
            let ejerform = this.getEjerforholdsTekstFraKode(ejfFeature.properties.ejerforholdskode)
            if (ejfFeature.properties.ejendeVirksomhed) {
              ejerNavn = ejfFeature.properties.ejendeVirksomhed.attributes.navn
              cvrNummer = ejfFeature.properties.ejendeVirksomhed.attributes.CVRNummer
              reklameBeskyttelse = ejfFeature.properties.ejendeVirksomhed.attributes.reklamebeskyttelse
              if (reklameBeskyttelse)
                reklameBeskyttelse = 'ja'
              else
                reklameBeskyttelse = 'Nej'
            } else if (ejfFeature.properties.ejendePerson) {
              if (ejfFeature.properties.ejendePerson.Person.Navn) {
                ejerNavn = ejfFeature.properties.ejendePerson.Person.Navn.adresseringsnavn

              }else
                ejerNavn = "-"
              reklameBeskyttelse = this.getBeskyttelse(ejfFeature.properties.ejendePerson.Person.Beskyttelser)
            } else if (ejfFeature.properties.ejeroplysninger.features) {
              if (ejfFeature.properties.ejeroplysninger.features.length > 0)
                ejerNavn = ejfFeature.properties.ejeroplysninger.features[0].properties.navn
              else
                ejerNavn = 'Ukendt'
              reklameBeskyttelse = 'Ukendt'

            }
            let detailItem = {
              type: "labelvalue",
              value: `${this.ejerAnon ? ejerform : ejerNavn}`,
              infoItems: [
                { type: "labelvalue", value: ejerAndelString ? ejerAndelString : '' },
                { type: "labelvalue", value: ejerAddresse }]
            }
            if (this.showHistory) {
              detailItem.infoItems.push({ type: "labelvalue", value: status })
              detailItem.infoItems.push({ type: "labelvalue", value: virkningfra, valueformat: "iso-date"})              
            }
            if (isOneOwnerCVR) {
              if (cvrNummer)
                detailItem.infoItems.push({ type: "link", link: 'https://datacvr.virk.dk/data/visenhed?&enhedstype=virksomhed&id=' + cvrNummer, linkTitle: cvrNummer })
              else
                detailItem.infoItems.push({ type: "labelvalue", value: '' })
            }
            if (this.showBeskyttelse)
              detailItem.infoItems.push({ type: "labelvalue", value: reklameBeskyttelse ? reklameBeskyttelse : "" })

            ownerDetailItemsList.append(detailItem)
          }
        }
        if (hasOwner) {
          //ownerDetailItemsList.items.sort((a, b) => a.virkningfra.status - b.properties.status)
          items.push(ownerDetailItemsList.asItem())
        }else
          items.push(noOwnerdetailItemsList.asItem())

      } catch (exception) {
        if (logger)
          logger.error(exception, "EjerskabMedStamoplysninger")
      }

      try {
        let admResponse = await bfeResult.searcher.fetcher.read("Ejerfortegnelse", "Ejerfortegnelsen", "EjendomsadministratorMedStamoplysninger", {BFEnr: bfeNummer}, this.getLogger())
        let hasAdmin = admResponse && admResponse.features && admResponse.features.length > 0

        if (hasAdmin) {
          for (let ejfFeature of admResponse.features) {
            let ejerNavn
            let cvrNummer
            let reklameBeskyttelse
            let ejerAddresse = await this.getEjfFeatureAdresse(ejfFeature)
            let ejerform
            if (ejfFeature.properties.virksomhed) {
              ejerform = 'Virksomhed'
              ejerNavn = ejfFeature.properties.virksomhed.attributes.navn
              cvrNummer = ejfFeature.properties.virksomhed.attributes.CVRNummer
              reklameBeskyttelse = ejfFeature.properties.virksomhed.attributes.reklamebeskyttelse

              if (reklameBeskyttelse)
                reklameBeskyttelse = 'ja'
              else
                reklameBeskyttelse = 'Nej'
            } else if (ejfFeature.properties.personEllerVirksomhed) {
              ejerform = 'Person eller virksomhed'
              ejerNavn = ejfFeature.properties.personEllerVirksomhed.features[0].properties.navn
              reklameBeskyttelse = 'Ukendt'
            }

            let detailItem = {
              type: "labelvalue",
              value: `${this.ejerAnon ? ejerform : ejerNavn}`,
              infoItems: [

                { type: "labelvalue", value: ejerAddresse }]
            }

            if (cvrNummer) {
              administratorDetailItemsList.infoItemsHeaders.push({ type: "link", label: "CVR" })
              detailItem.infoItems.push({ type: "link", link: 'https://datacvr.virk.dk/data/visenhed?&enhedstype=virksomhed&id=' + cvrNummer, linkTitle: cvrNummer })
            }

            if (this.showBeskyttelse) {
              administratorDetailItemsList.infoItemsHeaders.push({ type: "labelvalue", label: "Reklamebeskyttelse" })
              detailItem.infoItems.push({ type: "labelvalue", value: reklameBeskyttelse ? reklameBeskyttelse : "" })
            }
            administratorDetailItemsList.append(detailItem)
          }
          items.push(administratorDetailItemsList.asItem())
        }
      } catch (exception) {
        if (logger)
          logger.error(exception, "EjendomsadministratorMedStamoplysninger")
      }

      return items

    } catch (e) {
      if (logger)
        logger.error(e, "getEjerInfoItems")
      return ([{
        icon: icons.infoRed,
        type: "labelvalue",
        label: "getEjerInfoItems",
        value: e.message
      }])
    }
  }

  async getBygningPaaFremmedgrundItems(bfeResult) {
    const bpfg = bfeResult.data.bfeResponse.properties.bygningPaaFremmedGrund
    let itemtype
    if (bpfg.length == 0)
      itemtype = 'labelvalue'
    else
      itemtype = 'result'

    let header 
    if (bpfg.length == 0)
      header = "Bygninger på fremmed grund"
    else if (bpfg.length == 1)
      header = "Ejendommen indeholder 1 bygning på fremmed grund"
    else
      header = "Ejendommen indeholder " + bpfg.length + " bygninger på fremmed grund"
    
    let detailItemsList = new DetailItemsList({
      itemType: itemtype,
      header: header,
      name: "sfe_bfg"
    })
    if (bpfg.length == 0) {
      detailItemsList.append({
        type: "labelvalue",
        value: "Ejendommen indeholder ingen bygninger på fremmed grund"
      })
    } else {
      let idArray = []
      for (const b of bpfg)
        idArray.push(b.BFEnummer)
      
      let bygningPaaFremmedGrundResults = await bfeResult.searcher.gets(idArray, "bfg")
      
      for (let bygningPaaFremmedGrundResult of bygningPaaFremmedGrundResults) {
        detailItemsList.append({
          type: "result",
          result: bygningPaaFremmedGrundResult
        })
      }
    }

    return [detailItemsList.asItem()]
  }

  async getEjerlejlighedItems(bfeResult) {
    const ejl = bfeResult.data.bfeResponse.properties.ejerlejlighed
    let itemtype
    if (ejl.length == 0)
      itemtype = 'labelvalue'
    else
      itemtype = 'result'

    let header
    if (ejl.length == 0)
      header = "Ejerlejligheder"
    else if (ejl.length == 1)
      header = "Ejendommen indeholder 1 ejerlejlighed"
    else
      header = "Ejendommen indeholder " + ejl.length + " ejerlejligheder"

    let detailItemsList = new DetailItemsList({
      itemType: itemtype,
      header: header,
      name: "sfe_ejl"
    })
    if (ejl.length === 0) {
      detailItemsList.append({
        type: "labelvalue",
        value: "Ejendommen indeholder ingen ejerlejligheder"
      })
    } else {
      try {
        let idArray = []
        for (const b of ejl)
          idArray.push(b.BFEnummer)

        let ejerlejlighedsResults = await bfeResult.searcher.gets(idArray, "ejl")

        for (let ejerlejlighedResults of ejerlejlighedsResults) {
          detailItemsList.append({
            type: "result",
            result: ejerlejlighedResults
          })
        }
      } catch (exceptions) {
        return [{
          type: "labelvalue",
          label: "Ejerlejligheder",
          value: "Kunne ikke hente yderligere info fra MAT"
        }]
      }
      
      
    }

    return [detailItemsList.asItem()]
  }
  
  async getEjfFeatureAdresse(ejfFeature) {
    let adresse = ""
    if (ejfFeature.properties.ejendeVirksomhed) {
      let beliggenhedsadresse = ejfFeature.properties.ejendeVirksomhed.attributes.beliggenhedsadresse
      adresse = beliggenhedsadresse["CVRAdresse.vejnavn"] + " " + beliggenhedsadresse["CVRAdresse.husnummerFra"] + ", " + beliggenhedsadresse["CVRAdresse.postnummer"] + " " + beliggenhedsadresse["CVRAdresse.postdistrikt"]
      return adresse
    } else if (ejfFeature.properties.ejendePerson) {
      let person = ejfFeature.properties.ejendePerson.Person
      if (person.Standardadresse !== "")
        adresse = person.Standardadresse + ", " + person.CprAdresse.postnummer + " " + person.CprAdresse.postdistrikt
      return adresse
    } else if (ejfFeature.properties.ejeroplysninger) {
      if (ejfFeature.properties.ejeroplysninger.features && ejfFeature.properties.ejeroplysninger.features.length > 0) {
        let f = ejfFeature.properties.ejeroplysninger.features[0]
        adresse = f.properties.alternativAdresse.adresselinje1
        if (f.properties.alternativAdresse.adresselinje1)
          adresse = f.properties.alternativAdresse.adresselinje1
        if (f.properties.alternativAdresse.adresselinje2)
          adresse = adresse + ', ' + f.properties.alternativAdresse.adresselinje2
        if (f.properties.alternativAdresse.adresselinje3)
          adresse = adresse + ', ' + f.properties.alternativAdresse.adresselinje3
      }
      return adresse

    } else if (ejfFeature.properties.personEllerVirksomhed) {
      let f = ejfFeature.properties.personEllerVirksomhed.features[0]
      adresse = f.properties.alternativAdresse.adresselinje1
      if (f.properties.alternativAdresse.adresselinje1)
        adresse = f.properties.alternativAdresse.adresselinje1
      if (f.properties.alternativAdresse.adresselinje2)
        adresse = adresse + ', ' + f.properties.alternativAdresse.adresselinje2
      if (f.properties.alternativAdresse.adresselinje3)
        adresse = adresse + ', ' + f.properties.alternativAdresse.adresselinje3
      return adresse

    } else if (ejfFeature.properties.virksomhed) {
      let f = ejfFeature.properties.virksomhed.attributes.beliggenhedsadresse
      if (f["coNavn"])
        adresse = adresse + 'c/o: ' + f["coNavn"] + ', '

      adresse = adresse + f["CVRAdresse.vejnavn"] + ' ' + f["CVRAdresse.husnummerFra"] + ', ' + f["CVRAdresse.postnummer"] + ' ' + f["CVRAdresse.postdistrikt"]

      return adresse

    } else {
      return ''
    }
  }

  async getBeliggenhedsItems(ebrResponse, bfeResult) {
    let typeId = bfeResult.typeId
    let items = []
    let beliggenhedsTekst
    let id_lokalId

    if (typeId === "bfg" || typeId === "sfe") {
      if (ebrResponse.features[0].properties.husnummer.length > 0) {
        id_lokalId = ebrResponse.features[0].properties.husnummer[0].id_lokalId
        beliggenhedsTekst = ebrResponse.features[0].properties.husnummer[0].adgangsadressebetegnelse
      } else {
        return items
      }

    } else {
      //ejl
      if (ebrResponse.features[0].properties.adresse.length > 0) {
        id_lokalId = ebrResponse.features[0].properties.adresse[0].husnummer.id_lokalId
        beliggenhedsTekst = ebrResponse.features[0].properties.adresse[0].adressebetegnelse
      } else {
        return items
      }

    }
    if (this.dawaSearcher) {
      let dawaResult = await this.dawaSearcher.get(id_lokalId, "adresse")
      items.push({
        label: "Beliggenhed",
        type: "result",
        result: dawaResult
      })
    } else {
      items.push({
        type: "labelvalue",
        value: beliggenhedsTekst
      })
    }
    return items
  }

  async getJordstykkeTekst(bfeResponse) {
    if (bfeResponse.properties.jordstykke) {
      let jordstykkeCount = bfeResponse.properties.jordstykke.length
      let jordstykkeTekster = []
      if (jordstykkeCount > 0) {
        let jordstykke0 = bfeResponse.properties.jordstykke[0]
        let matnr = jordstykke0.properties.matrikelnummer
        let ejerlavsnavn = jordstykke0.properties.ejerlavsnavn
        jordstykkeTekster.push(matnr + ", " + ejerlavsnavn)
      }
      if (jordstykkeCount > 1)
        jordstykkeTekster.push("m.fl.")
      return jordstykkeTekster.join(" ")
    } else {
      return ""
    }
  }

  async getJordstykkeItems(bfeResponse) {
    if (bfeResponse.properties.jordstykke) {
      let jordstykkeCount = bfeResponse.properties.jordstykke.length
      if (jordstykkeCount > 0) {

        let itemsList = {
          type: this.geoSearcher ? "resultlist" : "labelvaluelist",
          header: jordstykkeCount === 1 ? "Matrikelnummer" : "Matrikelnumre",
          items: []
        }
        for (let jordstykke of bfeResponse.properties.jordstykke) {
          let matnr = jordstykke.properties.matrikelnummer
          if (this.geoSearcher) {
            let ejerlavskode = jordstykke.properties.ejerlavskode
            let matResult = await this.geoSearcher.get(ejerlavskode + "-" + matnr, "matrikelnumre")
            itemsList.items.push({
              type: "result",
              result: matResult
            })
          } else {
            let ejerlavsnavn = jordstykke.properties.ejerlavsnavn
            itemsList.items.push({
              type: "labelvalue",
              value: matnr + ", " + ejerlavsnavn
            })
          }
        }
        return [itemsList]
      }
    }
    return []
  }

  async getSamvurderingsBeskrivelsesItems(samvurderingsResult) {
    let detailItemsList = new DetailItemsList({
      header: samvurderingsResult.title,
      name: "samvurdering_info"
    })
    detailItemsList.append({
      type: "labelvalue",
      label: "Vurderingsejendoms-id",
      value: samvurderingsResult.data.vurderingsResponse.Vurderingsejendom.VURejendomsid
    })
    detailItemsList.append({
      type: "labelvalue",
      label: "ESR Ejd. nr.",
      value: samvurderingsResult.data.vurderingsResponse.Vurderingsejendom.ESRkommunenummer + "-" + samvurderingsResult.data.vurderingsResponse.Vurderingsejendom.ESRejendomsnummer
    })

    return [detailItemsList.asItem()]
  }

  async getSamvurderingsVurderingsItems(samvurderingsResult) {
    let handler = new VurderingsHandler({})
    let items = await handler.getLatestSamvurderingsItems(samvurderingsResult)
    return items
  }

  async getSamvurderingsBferesultItems(samvurderingsResult) {
    let bfeNumre = samvurderingsResult.data.vurderingsResponse.BFEnummerList
    let detailItemsList = new DetailItemsList({
      itemType: "result",
      header: "Ejendomme",
      name: "samvurdering_bfe_liste"
    })

    let bfeResults = await samvurderingsResult.searcher.gets(bfeNumre, "bfe")
    for (let bfeResult of bfeResults) {
      detailItemsList.append({
        type: "result",
        result: bfeResult
      })
    }
    return [detailItemsList.asItem()]
  }
  
  async getSamvurderingsEjerInfoItems(samvurderingsResult) {
    //https://github.com/Septima/septima-search/issues/399
    let bfeNumre = samvurderingsResult.data.vurderingsResponse.BFEnummerList
    let firstBfe = null
    for (let bfeNummer of bfeNumre) {
      let ejfResponse = await samvurderingsResult.searcher.fetcher.read("Ejerfortegnelse", "Ejerfortegnelsen", "EjerskabMedStamoplysninger", {BFEnr: bfeNummer}, this.getLogger())
      if (firstBfe === null) {
        firstBfe = { bfeNummer, ejerHash: JSON.stringify(ejfResponse).length }
      } else if (firstBfe.ejerHash !== JSON.stringify(ejfResponse).length) {
        return [{
          type: "labelvalue",
          value: "Ejendommene i samvurderingen har forskellige ejerforhold. Aktuelle ejerforhold kan ses på den enkelte ejendom"
        }]
      }
    }
    let bfeResult = await samvurderingsResult.searcher.get(firstBfe.bfeNummer, "bfe")
    let ejerItems = await this.getBfeEjerInfoItems(bfeResult)
    return ejerItems
  }
  
  async getEnergiMaerkeSection(inresult) {
    let detailItemsList = new DetailItemsList({
      header: "Energimærke",
      name: "bygning_energi"
    })

    try {
      let bfeEjendom = await this.dataApiFetcher.get("bestemtfastejendom", { bfenummer: `eq.${inresult.id}`, select: "*,esrejendom(*)" })
      if (bfeEjendom && bfeEjendom[0].husnummer_id) {
        let husnummerId = bfeEjendom[0].husnummer_id
        let husnummerInfos = await this.dataApiFetcher.get("husnummer", { id_lokalid: `eq.${husnummerId}` })
        if (husnummerInfos?.[0]) {
          let husnummerInfo = husnummerInfos[0]
          let postcode = husnummerInfo.postnr
          let street = husnummerInfo.vejnavn
          let number = husnummerInfo.husnummertekst
          let url = "https://sparenergi.dk/api/sparenergi/v2/energylabel.json?postcode=" + postcode + "&street=" + street + "&number=" + number
          let sparEnergiReply = await fetch2(url)
          if (sparEnergiReply && sparEnergiReply.energylabel && sparEnergiReply.energylabel.current_el) {
            detailItemsList.append({
              type: "labelvalue",
              label: "Klassifikation",
              value: sparEnergiReply.energylabel.current_el
            })
            detailItemsList.append({
              type: 'link',
              link: sparEnergiReply.energylabel.report_url,
              linkTitle: "Rapport"
            })
            return [detailItemsList.asItem()]
          }
        }
      }

    } catch (e) {
      detailItemsList.append({
        type: "labelvalue",
        value: "Energimærke ikke fundet"
      })
      return [detailItemsList.asItem()]
    }

    //hasn't found anything
    detailItemsList.append({
      type: "labelvalue",
      value: "Energimærke ikke fundet"
    })
    return [detailItemsList.asItem()]
  }
  getBeskyttelse(beskytArray) {
    if (beskytArray && beskytArray.length > 0) {
      let beskyttelse = beskytArray.map(b => b.Beskyttelse.beskyttelsestype)
      let strBeskyttelser = beskyttelse.join(', ')
      strBeskyttelser = strBeskyttelser.replace('markedsfoering', 'Markedsføring')
      strBeskyttelser = strBeskyttelser.replace('lokal_vejviser', 'Lokal vejviser')
      strBeskyttelser = strBeskyttelser.replace('navne_og_adresse', 'Navne og adresse')
      return strBeskyttelser
    } else {
      return "Ingen"
    }
  }
  getEjerforholdsTekstFraKode(kode) {
    const anvendelseskoder = {
      "10": {
        "tekst": "Privatpersoner eller interessentskab"
      },
      "20": {
        "tekst": "Almennyttig boligselskab"
      },
      "30": {
        "tekst": "Aktieselskab/anpartsselskab"
      },
      "40": {
        "tekst": "Forening, legat eller selvejende institution"
      },
      "41": {
        "tekst": "Privat andelsboligforening."
      },
      "50": {
        "tekst": "Den kommune, hvori ejendommen er beliggende."
      },
      "60": {
        "tekst": "Anden primærkommune"
      },
      "70": {
        "tekst": "Amtskommune"
      },
      "80": {
        "tekst": "Staten"
      },
      "90": {
        "tekst": "Andet"
      }
    }
    if (anvendelseskoder[kode])
      return anvendelseskoder[kode]["tekst"]
    else
      return kode
  }

}


