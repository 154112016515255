import SSSearcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
/**
 * @sspath Septima.Search.Tingbog
 */
export default class Searcher extends SSSearcher {
  constructor(options = {}) {
    if (!options.fetcher)
      throw new Error("TingbogsSearcher called without options.fetcher")

    if (!options.redataFetcher)
      throw new Error("TingbogsSearcher called without options.redataFetcher")

    super(options)

    this.source = "tingbogen"
    this.fetcher = options.fetcher
    this.redataFetcher = options.redataFetcher
    this.ejendomsSearcher = options.ejendomsSearcher
    this.types = {
      "servitut": new ResultType({ id: "servitut", singular: "Servitut", plural: "Servitutter", queryBehaviour: "none" }),
      "haeftelse": new ResultType({ id: "haeftelse", singular: "Hæftelse", plural: "Hæftelser", queryBehaviour: "none", geometrySupport: "none" })
    }
    this.registerType(this.source, this.types.servitut)
    this.registerType(this.source, this.types.haeftelse)
  }

  createRessource(url, params = null) {
    return this.fetcher.createRessource(url, params)
  }
  
  async getTingbogForBfe(bfeNummer) {
    return this.fetcher.getEjendomSummarisk(bfeNummer, this.logger)
  }
  
  // eslint-disable-next-line no-unused-vars
  async getServitutterForBfe(bfeNummer) {
    let queryResult = this.createQueryResult()
    let ejendomSummarisk = await this.getTingbogForBfe(bfeNummer)
    for (let servitut of ejendomSummarisk.Servitutter) {
      let result = queryResult.addResult(this.source, this.types.servitut.id, servitut.servitutnavn, null, null, servitut)
      result.id = bfeNummer + "|" + servitut.DokumentIdentifikator
      result.isComplete = false
    }
    return queryResult.getResults()
  }
  
  // eslint-disable-next-line no-unused-vars
  async getHaeftelserForBfe(bfeNummer) {
    let queryResult = this.createQueryResult()
    let ejendomSummarisk = await this.getTingbogForBfe(bfeNummer)
    let bfeResult = await this.ejendomsSearcher.get(bfeNummer, "bfe")
    for (let haeftelse of ejendomSummarisk.HaeftelserSeptima) {  //ValutaKode
      let titel = haeftelse.PrioritetNummer + (haeftelse.BeloebVaerdi !== "0" ? " - " + haeftelse.ValutaKode + " " + haeftelse.BeloebVaerdi : "") + " - " + haeftelse.HaeftelseType
      if (haeftelse.kreditorarray.length === 1)
        titel += " - " + haeftelse.kreditorarray[0].navn
      else if (haeftelse.kreditorarray.length > 1)
        titel += " - Flere kreditoter"

      let result = queryResult.addResult(this.source, this.types.haeftelse.id, titel, null, bfeResult.geometry, haeftelse)
      result.id = bfeNummer + "|" + haeftelse.DokumentIdentifikator
    }
    return queryResult.getResults()
  }

  async get(id, typeId) {
    let queryResult = this.createQueryResult()
    if (typeId === this.types.servitut.id) {
      let parts = id.split("|")
      let bfeNummer = parts[0]
      let dokumentIdentifikator = parts[1]
      let ejendomSummarisk = await this.getTingbogForBfe(bfeNummer)
      let servitutter = ejendomSummarisk.Servitutter
      for (let servitut of servitutter) {
        if (servitut.DokumentIdentifikator === dokumentIdentifikator) {
          let result = queryResult.addResult(this.source, this.types.servitut.id, servitut.servitutnavn, null, null, servitut)
          result.id = id
          result.isComplete = false
          result = await this.completeResult(result)
          return result
        }
      }
    }
    if (typeId === this.types.haeftelse.id) {
      let parts = id.split("|")
      let bfeNummer = parts[0]
      let dokumentIdentifikator = parts[1]
      let bfeResult = await this.ejendomsSearcher.get(bfeNummer, "bfe")
      let ejendomSummarisk = await this.getTingbogForBfe(bfeNummer)
      for (let haeftelse of ejendomSummarisk.HaeftelserSeptima) {
        if (haeftelse.DokumentIdentifikator === dokumentIdentifikator) {
          let titel = haeftelse.PrioritetNummer + (haeftelse.BeloebVaerdi !== "0" ? " - " + haeftelse.ValutaKode + " " + haeftelse.BeloebVaerdi : "") + " - " + haeftelse.HaeftelseType
          if (haeftelse.kreditorarray.length === 1)
            titel += " - " + haeftelse.kreditorarray[0].navn
          else if (haeftelse.kreditorarray.length > 1)
            titel += " - Flere kreditoter"
          let result = queryResult.addResult(this.source, this.types.haeftelse.id, titel, null, bfeResult.geometry, haeftelse)
          result.id = id
          return result
        }
      }
    }
  }
  
  async completeResult(result) {
    if (!result.isComplete) {
      if (result.typeId === this.types.servitut.id) {
        let parts = result.id.split("|")
        let bfeNummer = parts[0]
        let bfeResult = await this.ejendomsSearcher.get(bfeNummer, "bfe")
        let jordStykker = []
        if (bfeResult?.data?.bfeResponse?.properties?.jordstykke) {
          for (let jordstykke of bfeResult.data.bfeResponse.properties.jordstykke) {
            jordStykker.push(jordstykke.properties.matrikelnummer + ", " + jordstykke.properties.ejerlavsnavn)
          }
        }
        let dokumentIdentifikator = parts[1]
        let matrikelNummerItemsForServitut = await this.fetcher.getMatrikelNummerItemsForServitut(bfeNummer, dokumentIdentifikator)
        //Sammenstil
        for (let matrikelNummerItem of matrikelNummerItemsForServitut) {
          jordStykker.push(matrikelNummerItem.beskrivelse)
        }
        //kald jordstykke_geom_agg
        let reply = await this.redataFetcher.rpc("jordstykke_geom_agg", {jordstykker: jordStykker}, this.getLogger())
        result.geometry = reply[0].geom
      }
      result.isComplete = true
    }
    return result
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }

}