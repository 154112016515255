import { getString, setLocale, setLocaleByIdentifier, defaultLocale } from './resources/strings.js'
import icons from './resources/icons.js'
import { Class, inherit, extend, bind } from './utils.js'
import { getWKTParser } from './util/getWKTParser.js'
import Controller from './Controller.js'
import Query from './Query.js'
import QueryResult from './QueryResult.js'
import Result from './Result.js'
import ResultType from './ResultType.js'
import ControllerBuilder from './ControllerBuilder.js'
import Searcher from './searchers/Searcher.js'
import ArcGisSearcher from './searchers/ArcGisSearcher.js'
import ClientSearcher from './searchers/ClientSearcher.js'
import CVR_enhedSearcher from './searchers/CVR_enhedSearcher.js'
import DataSearcher from './searchers/DataSearcher.js'
import DawaSearcher from './searchers/DawaSearcher.js'
import DawaStednavnSearcher from './searchers/DawaStednavnSearcher.js'
import GeoStednavnSearcher from './searchers/geosearch/GeoStednavnSearcher.js'
import PlanSystemSearcher from './searchers/PlanSystemSearcher.js'

import S4IndexSearcher from './searchers/s4/S4IndexSearcher.js'
import S4AddressSearcher from './searchers/s4/S4AddressSearcher.js'
import SqSearcher from './searchers/s4/SqSearcher.js'

import S3Searcher from './searchers/S3Searcher.js'
import IsoChroneSearcher from './searchers/IsoChroneSearcher.js'

import SearchableData from './searchers/local/SearchableData.js'
import SearchableGeoJson from './searchers/local/SearchableGeoJson.js'

import GeometrySearchProvider from './searchers/providers/GeometrySearchProvider.js'

import DummyMoreHandler from './searchers/detailhandlers/DummyMoreHandler.js'
import DemoDetailsHandler from "./searchers/detailhandlers/DemoDetailsHandler.js"
import DokLinksForLokalplan from "./searchers/detailhandlers/DokLinksForLokalplan.js"

import View from './defaultview/View.js'
import DefaultView from './defaultview/DefaultView.js'
import AccordionDetailsLayout from './defaultview/AccordionDetailsLayout.js'
import DetailItemsRenderer from './defaultview/DetailItemsRenderer.js'
import SqDetailsHandler from './details/SqDetailsHandler.js'
import DetailsHandlerDef from './details/DetailsHandlerDef.js'
import ComposedDetailsHandler from './details/ComposedDetailsHandler.js'
import Details from "./details/Details.js"
import DetailItemsList from "./details/DetailItemsList.js"

import allTypes from './AllTypes.js'

import 'core-js'
import 'whatwg-fetch'
import './util/ie11.js'

import JordForureningsattestLinkHandler from "./searchers/detailhandlers/deprecated/JordForureningsattestLinkHandler.js"
import CvrLinkHandler from "./searchers/detailhandlers/deprecated/CvrLinkHandler.js"
import CvrInfoProvider from "./searchers/detailhandlers/deprecated/CvrInfoProvider.js"

import RegnskaberForCvrNummerHandler from "./searchers/detailhandlers/RegnskaberForCvrNummerHandler.js"
import S4InfoHandler from "./searchers/detailhandlers/S4InfoHandler.js"
import FilarkivLinkForAdresseMatrikelHandler from './searchers/detailhandlers/FilarkivLinkForAdresseMatrikelHandler.js'
import LifaLinkForAdresseMatrikelHandler from './searchers/detailhandlers/deprecated/LifaLinkForAdresseMatrikelHandler.js'
import LifaLinks from './searchers/detailhandlers/LifaLinks.js'
import EjdExplorerLinks from './searchers/detailhandlers/deprecated/EjdExplorerLinks.js'


import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel.js'
import * as reproject from './util/reproject.js'
import HusnummerInfoProvider from "./searchers/detailhandlers/HusnummerInfoProvider.js"
import PlanInfoProvider from "./searchers/detailhandlers/PlanInfoProvider.js"
import TjekDitNetForAdresseProvider from "./searchers/detailhandlers/TjekDitNetForAdresseProvider.js"
import InfoForKommuneProvider from "./searchers/detailhandlers/InfoForKommuneProvider.js"
import InfoForOpstillingsKredsProvider from "./searchers/detailhandlers/InfoForOpstillingskredsProvider.js"
import InfoForPolitidistriktProvider from "./searchers/detailhandlers/InfoForPolitidistriktProvider.js"
import InfoForPostdistriktProvider from "./searchers/detailhandlers/InfoForPostdistriktProvider.js"
import InfoForRetskredsProvider from "./searchers/detailhandlers/InfoForRetskredsProvider.js"
import InfoForSognProvider from "./searchers/detailhandlers/InfoForSognProvider.js"
import CvrVirksomhederForAdresseProvider from "./searchers/detailhandlers/CvrVirksomhederForAdresseProvider.js"
import JordStykkeInfoProvider from "./searchers/detailhandlers/JordStykkeInfoProvider.js"
import DagiInfoProvider from "./searchers/detailhandlers/DagiInfoProvider.js"
import InfoForGeoStednavnProvider from "./searchers/detailhandlers/InfoForGeoStednavnProvider.js"
import SkraafotoProvider from "./searchers/detailhandlers/SkraafotoProvider.js"

import ThrowingDetailsProvider from "./searchers/detailhandlers/ThrowingDetailsProvider.js"

import StaticDetailsProvider from "./searchers/detailhandlers/deprecated/StaticDetailsProvider.js"
import AdresserForHusnummerProvider from "./searchers/detailhandlers/AdresserForHusnummerProvider.js"
import HusnumreForVejProvider from "./searchers/detailhandlers/HusnumreForVejProvider.js"

import VidiLinkForGeometry from "./searchers/detailhandlers/VidiLinkForGeometry.js"
import VirksomhederMedBranchekodeRadiusforAdresse from "./searchers/detailhandlers/VirksomhederMedBranchekodeRadiusforAdresse.js"
import DistanceProvider from "./searchers/detailhandlers/DistanceProvider.js"
import RouteProvider from "./searchers/detailhandlers/RouteProvider.js"

import S4NearestHandler from "./searchers/detailhandlers/S4NearestHandler.js"
import CookieResultStore from "./defaultview/resultstore/CookieResultStore.js"
import HistorySearcher from "./searchers/HistorySearcher.js"

import MemoryResultStore from "./searchers/local/MemoryResultStore.js"
import ResultStore from "./searchers/local/ResultStore.js"
import StaticResultStore from "./searchers/local/StaticResultStore.js"
import ResultStoreSearcher from "./searchers/ResultStoreSearcher.js"
import LocalstorageResultStore from "./defaultview/resultstore/LocalstorageResultStore.js"
import HusnummerForAdresseProvider from "./searchers/detailhandlers/HusnummerForAdresseProvider.js"
import LinkedResultHandler from "./details/LinkedResultHandler.js"
import GenericWfsSearcher from "./searchers/GenericWfsSearcher.js"
import Redata from "./searchers/redata/Redata.js"
import Geoserver from "./searchers/geoserver/Geoserver.js"
import DataApi from './searchers/data-api/DataApi.js'
import GeoFa from './searchers/geofa/GeoFa.js'
import OffentligeLinksProvider from './searchers/detailhandlers/OffentligeLinksProvider.js'
import GSearch from "./searchers/geosearch/GSearch.js"
import GeoSearch from './searchers/geosearch/GeoSearch.js'
import Datafordeler from './searchers/datafordeler/Datafordeler.js'
import Tingbog from './searchers/tingbog/Tingbog.js'
import SolPotentiale from "./searchers/solpotentiale/SolPotentiale.js"
import ArcGisFeatureInfoProvider from "./searchers/detailhandlers/ArcGisFeatureInfoProvider.js"
import CowiGadefotoProvider from "./searchers/detailhandlers/CowiGadefotoProvider.js"

const Search = {
  locale: defaultLocale,
  icons,
  getString,
  setLocale,
  setLocaleByIdentifier,
  Controller,
  ControllerBuilder,
  Query,
  QueryResult,
  Result,
  ResultType,
  Searcher: Searcher,
  arcGisSearcher: ArcGisSearcher,
  ArcGisFeatureInfoProvider,
  ArcGisSearcher,
  ClientSearcher,
  CVR_enhedSearcher,
  DataSearcher,
  DawaSearcher,
  DawaStednavnSearcher,
  GeoStednavnSearcher,
  GeoSearch,
  GSearch,
  PlanSystemSearcher,
  S4IndexSearcher,
  S4AddressSearcher,
  S4InfoHandler,
  S4NearestHandler,
  S3Searcher,
  SqSearcher,
  SearchableData,
  SearchableGeoJson,
  View,
  DefaultView,
  AccordionDetailsLayout,
  DetailItemsRenderer,
  SqDetailsHandler,
  DetailsHandlerDef,
  GeometrySearchProvider,
  IsoChroneSearcher,
  getWKTParser,
  classRegistry: allTypes,
  DummyMoreHandler,
  DemoDetailsHandler,
  CvrVirksomhederForAdresseProvider,
  AdresserForHusnummerProvider,
  HusnumreForVejProvider,
  HusnummerForAdresseProvider,
  DokLinksForLokalplan,
  ComposedDetailsHandler,
  DetailItemsList,
  JordForureningsattestLinkHandler,
  CvrLinkHandler,
  CvrInfoProvider,
  RegnskaberForCvrNummerHandler,
  FilarkivLinkForAdresseMatrikelHandler,
  LifaLinkForAdresseMatrikelHandler,
  LifaLinks,
  EjdExplorerLinks,
  PlanInfoProvider,
  HusnummerInfoProvider,
  OffentligeLinksProvider,
  OffentligeLinksForAdresseMatrikel,
  TjekDitNetForAdresseProvider,
  reproject,
  InfoForKommuneProvider,
  JordStykkeInfoProvider,
  InfoForOpstillingsKredsProvider,
  InfoForPolitidistriktProvider,
  InfoForPostdistriktProvider,
  InfoForRetskredsProvider,
  InfoForSognProvider,
  DagiInfoProvider,
  ThrowingDetailsProvider,
  InfoForGeoStednavnProvider,
  SkraafotoProvider,
  StaticDetailsProvider,
  VidiLinkForGeometry,
  VirksomhederMedBranchekodeRadiusforAdresse,
  DistanceProvider,
  RouteProvider,
  ResultStore,
  MemoryResultStore,
  CookieResultStore,
  LocalstorageResultStore,
  StaticResultStore,
  ResultStoreSearcher,
  HistorySearcher,
  GenericWfsSearcher,
  LinkedResultHandler,
  CowiGadefotoProvider,
  Redata,
  Geoserver,
  DataApi,
  GeoFa,
  Details,
  Datafordeler,
  Tingbog,
  SolPotentiale
}

const version = 'BUILDVERSION'

export {
  Class,
  inherit,
  extend,
  bind,
  Search,
  version
}
